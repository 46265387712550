.c-download {
  position: relative;
  z-index: 1;
  background-color: $color-grey;
  box-shadow: 0 1.5rem 1.5rem $color-steel;
  border-radius: 0.25rem;
  margin: 0 2rem 0;
  padding: 1.5rem 1.5rem 2rem;
  max-width: calc( #{$global-max-width} - 10%);

  @include bp($mobile-breakpoint) {
    position: absolute;
    display: flex;
    align-items: flex-start;
    width: calc(100% - 4rem);
    top: 50%;
    left: 50%;
    margin: 0;
    transform: translate(-50%, -50%);
  }
}

.c-download__image {
  &.c-img {
    @include fp(width, 64px, 80px);
    @include fp(height, 64px, 80px);
  }

  flex: 1 0 auto;
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem $color-steel;
}

.c-download__content {
  margin-top: 1rem;
  color: $color-dark-blue;
  font-size: 0.875rem;

  @include bp($mobile-breakpoint) {
    margin-top: 0;
    margin-left: 2rem;
  }
}

.c-download__btn {
  width: 100%;
  flex: 1 0 auto;
  margin-top: 2rem;
  padding: 1rem;
  background-color: var(--main-color);
  border: 1px solid $color-steel;
  color: $color-dark-blue;

  @include bp($mobile-breakpoint) {
    align-self: center;
    width: initial;
    padding: 1rem 3.5rem;
    margin-top: 0;
    margin-left: 3rem;
  }
}
