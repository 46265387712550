///* ========================================================================
//   #GLOBAL
//   ======================================================================== */
// The global settings file contains any project-wide variables; things that
// need to be made available to the entire codebase.

$global-radius: 3rem !default;
$global-timing-func: cubic-bezier(0.9, 1, 0.7, 1);
$global-transition: all 300ms $global-timing-func !default;
$global-logo-size: 10rem;
$global-logo-height: 4rem;

/* Fonts
   ========================================================================== */

$font-primary: 'Montserrat', candara, segoe, segoe UI, optima, arial, sans-serif !default;
$font-headings: 'Syncopate', candara, segoe, segoe UI, optima, arial, sans-serif !default;

/* Breakpoints
   ========================================================================== */

$breakpoints: (
  small: 480px,
  medium: 768px,
  large: 1024px,
  xlarge: 1280px,
  wide: 1440px,
  full: 1600px,
);

$mobile-breakpoint: large;

$global-max-width: 1680px;

$max-width-breakpoint: $global-max-width + 1px;

/* Colors
   ========================================================================== */

// Colors shouldn't be used directly but reassigned
$color-white: #fff;
$color-black: #000;
$color-grey: #f2efef;
$color-dark-grey: #a6a4a4;

$color-dark-blue: #002d53;
$color-light-blue: #bfeaff;

$color-steel: rgba($color-dark-blue, 0.15);
$color-green: #bfffbf;
$color-pink: #c8c8e6;
$color-yellow: #fff9ab;

// Upscaler
$color-up-turquoise: #81adb5;
$color-up-pink: #ed7fff;

// Text
$color-bg: $color-white;
$color-text: $color-black;

// Links
$color-link: $color-dark-blue;
$color-hover: $color-steel;

// Borders
$color-border: $color-grey;

// Fills
$color-fill: $color-dark-blue;

// shadows
$color-shadow: $color-steel;

// Error
$color-error: #ff6767;

// Warning
$color-warning: #ff9c67;

// success
$color-success: #52cca3;

/* CSS Vars
   ========================================================================== */
:root {
  --app-height: 100%;
  --app-primary-color: #002d53;
  --plyr-color-main: #fff;
  --up-purple: #ed7fff;
}
