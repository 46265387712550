/* ==========================================================================
   #LISTS
   ========================================================================== */

ul {
  list-style: none;
  margin: 0;
}

li {
  margin-bottom: 0;

  > ul,
  > ol {
    margin: 0;
  }
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 1rem;
  margin-left: 1rem;
}
