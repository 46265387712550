/* ==========================================================================
   #HEADER
   ========================================================================== */

.c-header {
  z-index: 200;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: $color-grey;
  box-shadow: 0 1px 0.125rem $color-shadow;

  &.menu-open {
    height: 100vh;
    height: var(--app-height);
  }

  @include bp($mobile-breakpoint) {
    position: sticky;
    &.menu-open {
      height: auto;
    }
  }
}

.c-header__inner {
  position: relative;
  height: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: $global-logo-size auto;
  grid-template-rows: 4rem auto;
  grid-template-areas:
    'header-brand header-icons'
    'header-menu header-menu';

  @include bp($mobile-breakpoint) {
    height: initial;
    grid-template-columns: $global-logo-size auto auto;
    grid-template-rows: 4rem auto auto;
    grid-template-areas: 'header-brand header-menu header-icons';
  }
}

.c-header__brand {
  grid-area: header-brand;
}
.c-header__logo {
  width: 100%;
  height: 100%;
  padding: 0.25rem;
}

.c-header__menu {
  grid-area: header-menu;
  overflow: hidden auto;

  @include bp-down($mobile-breakpoint) {
    .menu-open & {
      padding: 6rem 2rem 0;

      width: 100%;
      height: 100%;
    }
  }
  @include bp(small) {
    margin-left: 0.5rem;
  }
  @include bp(large) {
    margin-left: 0.5rem;
  }
  @include bp(xlarge) {
    margin-left: 1.5rem;
  }
  @include bp(wide) {
    margin-left: 3.5rem;
  }
}

.c-header__end {
  grid-area: header-icons;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 0.5rem;
}

.c-header__btn {
  ~ .c-header__btn {
    margin-left: 0.5rem;
  }

  &:hover,
  &.is-active {
    background-color: $color-white;
    border-color: $color-steel;
  }

  &:last-of-type {
    margin-right: 1rem;
  }

  @include bp($mobile-breakpoint) {
    &:last-of-type {
      display: none;
    }

    &:nth-last-of-type(2) {
      margin-right: 1.5rem;
    }
  }

  @include bp($max-width-breakpoint) {
    &:nth-last-of-type(2) {
      margin-right: 0;
    }
  }
}

.c-header__btn--search {
  &.is-active {
    display: none;
  }
}
