/* ==========================================================================
   ARCHIVE
   ========================================================================== */

.c-archive {
  @include fp(padding-top, 48px, 96px);
  @include fp(padding-bottom, 120px, 80px);
  padding-left: 2rem;
  padding-right: 2rem;

  @include bp($mobile-breakpoint) {
    padding-left: 0;
    padding-right: 0;
  }
}

.c-archive--related-downloads {
  background-color: $color-grey;
}

.c-archive__headline {
  text-transform: uppercase;
  color: $color-dark-blue;
}

.c-archive__headline--downloads {
  margin-bottom: 0;
}

.c-archive__subtext,
.c-archive__subhead {
  text-align: center;
  font: normal 1rem $font-primary;
  color: $color-dark-blue;
}

.c-archive__subhead {
  margin: 0;
  text-transform: uppercase;
}

.c-archive__subtext {
  font-size: 0.875rem;
}

.c-archive__grid {
  @include bp($mobile-breakpoint) {
    display: flex;
    flex-flow: row wrap;
  }
}

.c-archive__item {
  margin-bottom: 1rem;

  @include bp($mobile-breakpoint) {
    width: calc(#{percentage(1/4)} - 0.75rem); // only three items get margin left 1rem
    margin-left: 1rem;

    &:first-child,
    &:nth-child(4n+1) {
      margin-left: 0;
    }

    &:nth-last-child(-n+4) {
      margin-bottom: 0;
    }
  }

  .c-archive__grid--half & {
    @include bp($mobile-breakpoint) {
      width: calc(#{percentage(1/2)} - 0.75rem); // only three items get margin left 1rem
      margin-left: 1rem;

      &:first-child,
      &:nth-child(2n+1) {
        margin-left: 0;
      }

      &:nth-last-child(-n+4) {
        margin-bottom: 1rem;
      }
    }
  }
}
