.c-widget--has-quick-check .c-parallax-content__editor-content {
  p {
    font-size: 1.5rem;
    font-weight: 600;
    color: #fff;
    @include bp-down(medium) {
      font-size: 1.125rem;
      line-height: 1.3;
    }
  }
}
.c-widget--has-quick-check .c-parallax-content__inner {
  justify-content: space-between;
}

.c-widget--has-quick-check .c-parallax-content-wrapper {
  display: flex;
  align-items: flex-end;
  column-gap: 3rem;
  > * {
    flex: 0 1 50%;
  }
  @include bp-down(large) {
    display: block;
  }
}

.up-quick-check {
  background-color: rgba(237, 127, 255, 0.92);
  color: $color-dark-blue;
  padding: 3rem 3rem 1.5rem;
  border-radius: 6px;
  overflow: hidden;
  max-width: 540px;
  margin-left: auto;
  @include bp-down(medium) {
    padding: 1.5rem;
  }
  h3 {
    margin-bottom: 3rem;
  }
  h3 span {
    -webkit-text-stroke: 1px $color-dark-blue;
    color: transparent;
    display: block;
  }

  h4 {
    font-size: 1rem;
    font-weight: 600;
    font-family: $font-primary, sans-serif;
    margin-bottom: 1rem;
  }

  h5 {
    text-transform: none;
    font-family: $font-primary, sans-serif;
    font-size: 1.125rem;
    line-height: 1.4;
  }

  ul {
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
  }
  li {
    font-weight: bold;
    span {
      font-weight: 600;
      margin-bottom: 0.5rem;
    }
  }

  .questions {
    overflow: hidden;
    position: relative;
    width: 100%;
    .page {
      z-index: 0;
      margin-bottom: 2rem;
      &.page--active {
        z-index: 1;
        display: block;
      }
    }
  }

  .page.page--result {
  }

  .buttons {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: start;
    margin-bottom: 1.5rem;
    position: relative;
    button {
      border: none;
      white-space: nowrap;
    }
    button:disabled {
      visibility: hidden;
      opacity: 0;
      display: none;
    }
    button:not(.swiper-button-prev) {
      grid-column-start: 2;
    }
    button.swiper-button-prev {
      grid-column-start: 1;
    }
    button[type='submit'] {
      display: none;
    }
  }

  &.on-submit-page button[type='submit'] {
    display: block;
  }

  .swiper-pagination {
    position: relative;
    bottom: unset;
    left: unset;
  }
  .swiper-pagination-bullet {
    width: 11px;
    height: 11px;
    border: 1px solid $color-dark-blue;
    background: transparent;
    opacity: 1;
    &.swiper-pagination-bullet-active {
      background: $color-dark-blue;
    }
  }
}

.up-quick-check-checkbox {
  display: block;
  z-index: 1;
  position: relative;
  user-select: none;
  transform: translate3d(0, 0, 0);
  label {
    display: flex;
    gap: 1rem;
    padding: 0.25rem 0.5rem 0.25rem 0;
    justify-content: space-between;
    font-weight: 500;
    line-height: 1.3;
    font-size: 1.125rem;
    user-select: none;
  }
  @include bp-down(medium) {
    font-size: 1rem;
  }
  label,
  input {
    cursor: pointer;
  }
  input {
    margin-left: 1rem;
  }
}
.up-quick-check-checkbox input[type='checkbox'] {
  appearance: none;
  flex: 0 0 1.6em;
  background-color: $color-dark-blue;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.6em;
  height: 1.6em;
  border: 0.15em solid currentColor;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  padding: 0;
  &::before {
    content: '';
    width: 0.75em;
    height: 0.75em;
    clip-path: polygon(
      89.57% 26.7%,
      100% 38.02%,
      36.4% 100%,
      0% 62.25%,
      10.95% 51.69%,
      36.4% 78.08%
    );
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em rgb(237, 127, 255);
    background-color: CanvasText;
  }
  &:focus {
    outline: 2px solid currentColor;
    outline-offset: 2px;
  }
  &:checked::before {
    transform: scale(1);
  }
}

.up-quick-check--form {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  overflow: visible;
  padding: 0.25rem;
  margin-top: 2rem;
  input {
    border: 1px solid $color-dark-blue;
    box-shadow: none;
    border-radius: 7px;
    padding: 0.75rem 1.25rem;
    outline: none;
    background: transparent;
    &::placeholder {
      opacity: 0.75;
      color: $color-dark-blue;
    }
  }
}
