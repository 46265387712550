.up-event-calendar-block {
  --event-calendar-border-radius: 6px;
  h2 {
    font-size: 3.75rem;
    color: $color-dark-blue;
    line-height: 1;
    span {
      display: block;
      color: transparent;
      -webkit-text-stroke: 1px $color-dark-blue;
    }
    @include bp-down(xlarge) {
      font-size: 3rem;
    }
    @include bp-down(small) {
      font-size: 2.25rem;
    }
  }

  .up-event-calendar-block--cta {
    margin-top: 2rem;
    text-align: right;
  }
}

.up-event-calendar__wrapper {
  display: flex;
  border-radius: var(--event-calendar-border-radius) 0 0
    var(--event-calendar-border-radius);
  > .column {
    flex: 0 0 50%;
    position: relative;
    justify-content: center;
    overflow: hidden;
    background-color: #fff;
  }
  > .column:first-child {
    overflow: hidden;
    border-radius: var(--event-calendar-border-radius) 0 0
      var(--event-calendar-border-radius);
  }
  > .column:last-child {
    border-radius: 0 var(--event-calendar-border-radius)
      var(--event-calendar-border-radius) 0;
  }
  @include bp-down($mobile-breakpoint) {
    flex-direction: column-reverse;
    .column:first-child {
      aspect-ratio: 1;
    }
  }
}

.up-event-calendar--event-view {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
  .date {
    right: 1.5rem;
    top: 1.5rem;
    background: #fff;
    color: $color-dark-blue;
    border-radius: 10px;
    padding: 1.25rem;
    font-size: 1.325rem;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    line-height: 1;
    font-variant: tabular-nums;
    position: absolute;
    z-index: 1;
    span:first-child {
      font-size: 2rem;
    }
    @include bp-down(medium) {
      padding: 1rem;
      font-size: 1.125rem;
      span:first-child {
        font-size: 1.6rem;
      }
    }
  }
}

.up-event-calendar--event-view-item {
  display: none;
  height: 100%;
  position: relative;
  &.is-selected {
    display: block;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .color-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $color-dark-blue;
    mix-blend-mode: darken;
    opacity: 0.6;
  }
  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    height: 100%;
  }

  .text {
    padding: 0 2.5rem 2rem;
    @include bp-down($mobile-breakpoint) {
      padding: 0 1.5rem 3rem;
    }
  }

  h3 {
    color: #fff;
    line-height: 1.4;
    font-size: 1.25rem;
    letter-spacing: -0.5px;
    @include bp-down(medium) {
      font-size: 1.125rem;
      line-height: 1.4;
    }
  }

  &:not(:first-child) .date {
    display: none;
  }
}

.up-event-calendar--calendar-view {
  padding: 3rem 4rem 2rem;

  @include bp-down(xlarge) {
    padding: 3rem 2rem;
  }

  @include bp-down(medium) {
    padding: 3rem 1.5rem;
  }
}

.up-event-calendar {
  max-width: 540px;
  container: event-calendar-column / inline-size;
  color: $color-dark-blue;
  margin: 0 auto;
  .browser {
    display: flex;
    justify-content: center;
    align-items: baseline;
    margin-bottom: 2rem;
    div {
      font-weight: bold;
      font-size: 1.2rem;
      width: 180px;
      text-align: center;
    }
    button {
      background: transparent;
      border: none;
      cursor: pointer;
      padding: 0 1rem;
      svg {
        width: 0.8em;
        height: 0.8em;
        fill: $color-dark-grey;
        transition: fill 0.2s;
      }
      &:first-child svg {
        transform: rotate(180deg);
        transform-origin: center;
      }
      &:hover svg {
        fill: $color-dark-blue;
      }
      &:active svg {
        fill: $color-light-blue;
      }
    }
    button[disabled] {
      cursor: default;
    }
    button[disabled] svg {
      fill: $color-grey;
    }
  }

  .header,
  .month {
    font-size: 1.1rem;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
  }

  .header {
    text-align: center;
  }

  .month {
    display: none;
    &.month--active {
      display: grid;
      grid-template-rows: repeat(6, 1fr);
    }
  }
  .day {
    aspect-ratio: 1;
    display: flex;
    line-height: 1;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    text-decoration: none;
    border: none;
    position: relative;
    background: transparent;
    margin: 2cqw;
    @container event-calendar-column (max-width: 450px) {
      margin: 1.5cqw;
    }
    @container event-calendar-column (max-width: 360px) {
      margin: 1.25cqw;
    }
    span {
      position: relative;
    }
    &.day--not-in-month {
      color: transparent;
    }
    &.day--has-events {
      cursor: pointer;
      color: #fff;
      background: $color-dark-blue;
      font-weight: bold;
      padding: 0;
    }

    &.day--has-events:not(.day--has-thumbnail) {
      background: $color-dark-blue;
    }

    &.day--selected {
      cursor: default;
    }
    &.day--selected img {
      mix-blend-mode: difference;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
      opacity: 0.6;
      transition: filter 0.2s ease-in-out;
    }
    &.day--selected img {
      opacity: 0.8;
      mix-blend-mode: difference;
    }
    &:hover:not(.day--selected) img {
      filter: contrast(1.4);
    }

    .event-count-badge {
      position: absolute;
      top: -7px;
      right: -7px;
      width: 24px;
      height: 24px;
      line-height: 25px;
      text-align: center;
      background: var(--up-purple);
      border-radius: 50%;
      font-weight: bold;
      font-size: 1rem;
      color: white;
      z-index: 1;
    }
  }
}
