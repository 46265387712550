/* ==========================================================================
   IN VIEW
   ========================================================================== */

.u-inview {
  @include anim($dur: 0.6s);

  transition-property: transform, opacity;
  transform: translateY(20%);
  opacity: 0;

  &.is-active {
    transform: translateY(0);
    opacity: 1;
  }
}
