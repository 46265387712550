/* ==========================================================================
   CTA TILES
   ========================================================================== */

.c-tiles {
  @include fp(margin-top, 24px, 48px);

  @include bp($mobile-breakpoint) {
    display: flex;
    width: 100%;
  }
}

.c-tiles--cards {
  @include bp($mobile-breakpoint) {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.c-tile {
  @include fp(padding, 16px, 32px);
  @include fp(padding-bottom, 32px, 48px);

  background-color: $color-white;
  border: 1px solid $color-steel;
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem $color-steel;
  margin-bottom: 2rem;

  &:last-child {
    margin-bottom: 0;
  }

  @include bp($mobile-breakpoint) {
    width: calc(#{percentage(1/3)} - 0.75rem);
    margin-left: 1rem;
    margin-bottom: 0;

    &:nth-child(3n+1) {
      margin-left: 0;
    }
  }
}

.c-tile--card {
  @include bp($mobile-breakpoint) {
    margin-bottom: 1rem;
  }
}

.c-tiles-loadmore {
  @include fp(margin-top, 40px, 80px);

  display: block;
  margin: {
    left: auto;
    right: auto;
  };
  border: 1px solid $color-steel;

  .c-widget--has-dark-bg & {
    border-color: $color-white;
  }
}

.c-tile--card {
  padding: 0;

  @include bp($mobile-breakpoint) {
    display: flex;
    flex-direction: column;
  }
}

.c-tile--card-hidden {
  display: none;
  transform: translateY(1rem);
  opacity: 0;
}

.c-tile__header {
  display: flex;
  align-items: flex-start;
}

.c-tile__header--card {
  @include fp(padding-top, 32px, 64px);
  @include fp(padding-bottom, 32px, 64px);

  background-color: $color-grey;
  display: flex;
  justify-content: center;
}

.c-tile__headline {
  font: 700 normal 0.875rem/1.57 $font-primary;
  text-transform: none;
  margin-left: 1rem;
  margin-top: 0.5rem;
  color: $color-dark-blue;
}

.c-tile__headline--card {
  margin: 0.5rem 0 0;
}

.c-tile__subheadline {
  margin: 0;
}

.c-tile__img {
  box-shadow: 0 0.5rem 1rem $color-steel;
  border: 1px solid $color-steel;
  border-radius: 0.25rem;
  max-width: 80px;
}

.c-tile__img--card {
  box-shadow: none;
  border-radius: 0;
  border: none;
  width: auto;
  max-height: 5rem;
  max-width: initial;
}

.c-tile__content {
  margin-top: 2rem;
  font-size: 0.875rem;
  line-height: 1.57;
  color: $color-dark-blue;
}

.c-tile__content--card {
  @include fp(padding, 16px, 32px);
  @include fp(padding-top, 16px, 16px);
  @include fp(padding-bottom, 16px, 24px);

  margin-top: 0;

  @include bp($mobile-breakpoint) {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}

.c-tile__text {
  margin-top: 1rem;
}

.c-tile__btn {
  display: inline-block;
  width: auto;
  margin-top: 2rem;
  font-weight: 600;
  padding: 1rem 1.5rem;
}

.c-tile__btn--card {
  span {
    @include anim($prop: transform);

    display: inline-block;
  }

  &:hover {
    span {
      transform: translateX(0.25rem);
    }
  }
}

.c-tile__btn-wrap {

  @include bp($mobile-breakpoint) {
    margin-top: auto;

  }
}
