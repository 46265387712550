/* ==========================================================================
   PARALLAX CONTENT
   ========================================================================== */

.c-parallax-content {
  position: relative;

  &.has-top-gradient {
    &::before {
      @include ps();

      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 50%;
      z-index: -1;
      background-image: linear-gradient(
        to bottom,
        var(--top-gradient-color) 20%,
        transparent
      );
      @include bp-down($mobile-breakpoint) {
        height: 30%;
      }
    }
  }
  &.has-bottom-gradient {
    &::after {
      @include ps();

      display: block;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 50%;
      z-index: -1;
      background-image: linear-gradient(
        to top,
        var(--bottom-gradient-color) 40%,
        transparent
      );
      @include bp-down($mobile-breakpoint) {
        height: 30%;
      }
    }
  }
}

.c-parallax-content__inner {
  @include fp(padding-top, 80px, 140px);
  @include fp(padding-bottom, 96px, 104px);

  min-height: var(--app-height);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  @include bp-down($mobile-breakpoint) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.c-parallax-content__headline {
  &,
  > * {
    @include fp(font-size, 32px, 64px);
    @include fp(line-height, 32px, 64px);
  }

  color: var(--group-color);

  .c-text--outline {
    -webkit-text-stroke: 1px var(--group-color);
  }

  .c-widget--has-dark-bg & {
    color: $color_white;
  }

  @include bp($mobile-breakpoint) {
    > * {
      hyphens: none;
      word-break: initial;
      word-wrap: initial;
    }

    &--center {
      width: percentage(1/2);
      margin-left: percentage(1/4);
    }

    &--right {
      width: percentage(1/2);
      margin-left: percentage(2/4);
    }
  }
}

.c-parallax-content__editor-content,
.c-parallax-content__label,
.c-parallax-content__additional-content,
.c-parallax-content__additional-label {
  color: $color-dark-blue;

  @include bp($mobile-breakpoint) {
    .c-widget--has-dark-bg & {
      color: $color_white;
    }
  }
}

.c-parallax-content__image {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -2;
  opacity: 0;

  &--additional {
    opacity: 1;
    position: relative;
    display: block;
    margin: 4rem auto 0;
    width: initial;
    height: auto;
    z-index: initial;
  }

  .c-img__full::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba($color: #fff, $alpha: 0.75);

    @include bp($mobile-breakpoint) {
      content: unset;
    }
  }
}

.c-parallax-content__editor-content {
  @include fp(margin-top, 32px, 64px);

  &.below {
    @include bp($mobile-breakpoint) {
      margin-top: auto;
    }
  }
}

.c-parallax-content__additional-data {
  @include fp(margin-top, 96px, 240px);
}

.c-parallax-content__additional-content {
  &,
  > * {
    @include fp(font-size, 96px, 208px);
    @include fp(line-height, 96px, 208px);
  }
}

.c-parallax-content__additional-label {
  display: block;
  text-align: center;
}

.c-parallax-content__headline,
.c-parallax-content__label,
.c-parallax-content__btn,
.c-parallax-content__editor-content,
.c-parallax-content__additional-label,
.c-parallax-content__image--additional {
  @include anim();

  opacity: 0;
  transform: translateY(2rem);

  &.is-visible {
    opacity: 1;
    transform: translateY(0);
  }
}

.c-parallax-content__btn-wrapper {
  margin-top: 3rem;
  text-align: center;
}
