/* ==========================================================================
   PILL
   ========================================================================== */

.c-pill {
  display: block;
  border: 2px solid $color-dark-blue;
  border-radius: $global-radius;
  padding: 0.5rem 1.25rem;
  font: 600 0.875rem $font-primary;
}

.c-pill--small {
  padding: 0.25rem 0.75rem;
}

.c-pill--white {
  border-color: $color-steel;
}

.c-pill--reactive {
  display: inline-block;
  border-color: var(--font-color);
  color: var(--font-color);
}
