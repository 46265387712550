/* ==========================================================================
   WIDGETS
   ========================================================================== */
$widget_spaces: default 64px 140px, small 32px 80px, big 96px 180px;
$widget_space_dirs: top, bottom;

@each $spaceName, $min, $max in $widget_spaces {
  @if ($spaceName == none) {
    .c-widget--has-space-none {
      padding: 0;
    }
  } @else {
    @each $dir in $widget_space_dirs {
      .c-widget--has-space-#{$dir}-#{$spaceName} {
        @include fp(padding-#{$dir}, $min, $max);
      }
    }
  }
}

.c-widget {
  position: relative;
  z-index: 1;
  background-color: var(--bg-color);
  color: var(--font-color);

  &:not(.c-widget--has-space-bottom-default):not(.c-widget--has-space-bottom-small):not(.c-widget--has-space-bottom-big):not(.c-widget--story-parallax):not(.c-widget--pageintro) {
    padding-bottom: 1px;
  }
}

.c-widget--focus-markets {
  z-index: 2;
}

.c-widget--text,
.c-widget--video-slider,
.c-widget--image-gallery,
.c-widget--job-apply,
.c-widget--related-jobs,
.c-widget--cta-tiles,
.c-widget--video-text,
.c-widget--checklist,
.c-widget--cta-image-tiles {
  padding: {
    left: 2rem;
    right: 2rem;
  }
  @include bp($mobile-breakpoint) {
    padding: {
      left: 0;
      right: 0;
    }
  }
}

.c-widget--text .c-text > *:last-child {
  margin-bottom: 0;
}

.c-widget--full-height {
  position: relative;
  min-height: var(--app-height);
  padding: 2rem 0 3rem;

  @include bp($mobile-breakpoint) {
    padding: 0;
    display: flex;
  }
}

.c-widget--section-collapse {
  //@include fp(padding-top, 96px, 200px);
  //@include fp(padding-bottom, 96px, 180px);

  display: flex;
  flex-flow: column wrap;
}

.c-widget--story-questions {
  max-height: initial;
}

.c-widget--fact-grid {
  @include bp-down($mobile-breakpoint) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.c-widget--cta-tiles {
  @include fp(padding-top, 48px, 88px);
  @include fp(padding-bottom, 56px, 104px);
}

.c-widget--story-parallax {
  background-color: var(--group-color);
}

.c-widget--cta {
  //background-color: $color-white;
}

// Header
.c-widget__header {
  .c-widget--story &,
  .c-widget--statement & {
    padding: 0 2rem;
  }

  .c-widget--image-gallery & {
    margin-bottom: 2em;
  }

  @include bp($mobile-breakpoint) {
    .c-widget--story &,
    .c-widget--statement & {
      padding: 0;
    }
  }
}

.c-widget__header--has-btn {
  @include bp($mobile-breakpoint) {
    display: flex;
    align-items: flex-start;
  }
}

.c-widget__header-btn {
  margin-top: 1rem;

  @include bp($mobile-breakpoint) {
    margin-top: 0;
    margin-left: auto;
  }
}

// Main
.c-widget__main {
  @include fp(margin-top, 24px, 50px);
}

// Bg
.c-widget__bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
}

// Headline
.c-widget__headline {
  @include fp(font-size, 32px, 40px);

  text-transform: uppercase;
  color: $color-dark-blue;
  color: var(--font-color);
  font-family: $font-headings;
  line-height: 1.25;
  margin: 0;
}

.c-widget__headline--story-intro {
  @include fp(font-size, 32px, 64px);

  position: relative;
  z-index: 1;
  line-height: 1;
  padding: 0 2rem;
  color: $color-white;

  h1 {
    font-size: inherit;
  }

  @include bp($mobile-breakpoint) {
    padding: 0;
  }
}

.c-widget__subheadline {
  margin: 0;
  color: $color-dark-blue;
  color: var(--font-color);
  font: normal 0.75rem $font-primary;
  letter-spacing: 1.8px;
  text-transform: uppercase;

  @include bp($mobile-breakpoint) {
    font-size: 1rem;
    letter-spacing: 2.4px;
  }
}

// Logo element
.c-widget__logo-element {
  position: relative;
  z-index: 0;
  width: 100%;
  margin-bottom: -50%;

  svg {
    position: absolute;
    top: 45%;
    transform: translateY(-50%);
    left: 2rem;
    width: calc(100% - 4rem);
    margin: 0 auto;
    stroke: none;
    stroke-width: 0;
    visibility: hidden;
  }

  &::before {
    @include ps($pos: relative);

    display: block;
    width: 100%;
    max-width: calc(#{$global-max-width} - 10%);
    margin: 0 auto;
    padding-bottom: 100%;
    background-image: radial-gradient(#fff, transparent 70%);
    opacity: 0.4;
  }

  @include bp($mobile-breakpoint) {
    margin-bottom: 0;

    svg {
      width: 100%;
      max-width: calc(#{$global-max-width} - 10%);
      margin: 0 auto;
      height: 100%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &::before {
      padding-bottom: 0;
      height: var(--app-height);
      max-height: 60rem;
    }
  }
}

.c-widget__gradient {
  display: block;
  width: 90%;
  max-width: 31.5rem;
  margin: 3.5rem auto 0;
  background-image: radial-gradient(var(--main-color), transparent 70%);

  &::before {
    content: '';
    display: block;
    height: 0;
    width: 1px;
    padding-bottom: 100%;
  }

  @include bp($mobile-breakpoint) {
    position: relative;
    margin: -15.5rem 0 0; // max-width / 2
    transform: translateX(-100%);
    left: 100%;
  }
}

.c-widget__story-cta {
  position: relative;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  color: var(--main-color);
}

.c-widget__story-btn {
  position: relative;
  padding: 1rem 2rem;
  margin-top: 2rem;
  border-color: currentColor;
  color: currentColor;
}

.c-widget__story-cta-icon {
  margin-top: 2rem;
  width: 1rem;
  height: 1rem;
  fill: currentColor;
}

.c-widget__inner {
  .c-widget--full-height &,
  .c-widget--story-chapter &,
  .c-widget--cta-cards & {
    padding: 0 2rem;
    width: 100%;
  }

  .c-widget--full-height & {
    min-height: var(--app-height);
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    max-width: calc(#{$global-max-width} - 10%);
  }

  @include bp($mobile-breakpoint) {
    .c-widget--full-height & {
      min-height: initial;
    }
  }
}

.c-widget__inner--scrolly {
  justify-content: flex-start;
}

.c-widget__inner--collapse {
  @include anim($prop: height, $dur: 0.6s);

  position: relative;
  overflow: hidden;
  z-index: 0;

  &::before {
    @include anim();
    @include ps();

    z-index: 2;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-image: linear-gradient(to top, $color-white, transparent 80%);
  }

  .c-widget--has-bg & {
    &::before {
      background-image: linear-gradient(
        to top,
        var(--bg-color),
        transparent 80%
      );
    }
  }

  &.is-opened {
    &::before {
      visibility: hidden;
      opacity: 0;
    }
  }
}

.c-widget__collapse-btn {
  //@include fp(margin-top, 3rem, 5rem);

  position: relative;
  left: 50%;
  transform: translateX(-50%);
  color: var(--font-color);
  border-color: currentColor;
  text-transform: uppercase;
  order: 1;
  align-self: flex-start;
}

.c-widget__text-content {
  @include bp($mobile-breakpoint) {
    .c-widget--has-sidebar & {
      display: flex;
    }
  }
}

.c-widget__content {
  @include bp($mobile-breakpoint) {
    .c-widget--has-sidebar & {
      width: percentage(6/10);
    }
  }
}

.c-widget__sidebar {
  @include bp($mobile-breakpoint) {
    .c-widget--has-sidebar & {
      width: percentage(2/10);
      margin-left: percentage(1/10);
    }
  }
}
