/* ==========================================================================
   HERO
   ========================================================================== */

.c-hero {
  position: relative;
  z-index: 2;
  background-color: $color-white;
  padding-bottom: 4.5rem;

  @include bp($mobile-breakpoint) {
    padding-bottom: 0;
  }
}

.c-hero__inner {
  @include bp($mobile-breakpoint) {
    display: flex;
    align-items: center;

    .c-hero--is-right & {
      flex-direction: row-reverse;
    }

    .c-widget--hero-tags & {
      align-items: initial;
    }
  }
}

.c-hero__image {
  position: relative;
  margin: 0 auto;
  background: no-repeat 50% 50% / cover;
  max-width: 100%;
  width: 100%!important;
  height: 100%;
  z-index: 1;

  &::after {
    @include ps();
    bottom: 0;
    right: 0;
    width: 100%;
    height: 40%;
    background-image: linear-gradient(to top, currentColor, transparent);
  }

  .c-widget--hero-tags & {
    &::before {
      content: '';
      display: block;
      padding-bottom: 100%;
    }

    &::after {
      display: none;
    }
  }

  @include bp($mobile-breakpoint) {
    margin: initial;
    width: 50%!important;

    &::after {
      width: 40%;
      height: 100%;
      background-image: linear-gradient(to right, transparent, currentColor);
      z-index: 1;
    }

    .c-hero--is-right & {
      &::after {
        right: initial;
        left: 0;
        background-image: linear-gradient(to left, transparent, currentColor);
      }
    }

    .c-widget--hero-tags & {
      width: 50%;
    }
  }
}

.c-hero__content {
  flex: 1 0 auto;
  max-width: 26.25rem;
  padding: 0 2rem;
  color: $color-dark-blue;

  .c-widget--hero-tags & {
    padding: 3rem 2rem;
    align-self: center;
    max-width: 31rem;
  }

  .c-widget--has-font-color & {
    color: var(--font-color)
  }

  @include bp($mobile-breakpoint) {
    padding: 0;
    width: 50%;
    max-width: initial;

    .c-widget--hero-tags & {
      @include fp(margin, 64px, 104px);

      padding: 0;
    }
  }
}

.c-hero__content-inner {
  max-width: 26.25rem;
  margin: 0 auto;

  @include bp($mobile-breakpoint) {
    padding: 2rem 0;
  }

  @include bp(xlarge) {
    padding: 0;
  }
}

.c-hero__button {
  @include fp(margin-top, 24px, 48px);
}

.c-hero__tags {
  margin-top: 4rem;
}
