/* ==========================================================================
   #LINKS
   ========================================================================== */

a {
  color: $color-link;
  text-decoration: underline;

  &:hover {
    color: $color-hover;
  }

  .c-text--content & {
    color: var(--font-color);

    .c-widget--has-dark-bg & {
      &:hover {
        color: rgba(255, 255, 255, 0.5);
      }
    }
  }
}
