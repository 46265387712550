/* ==========================================================================
   FORM
   ========================================================================== */

.c-form__columns {
  +.c-form__columns {
    margin-top: 2rem;
  }

  @include bp($mobile-breakpoint) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    +.c-form__columns {
      margin-top: 0;
    }
  }
}

.c-form__column {
  @include bp($mobile-breakpoint) {
    width: calc(50% - 2rem);

    &.is-full {
      width: 100%;
    }

    &.is-1-3 {
      width: calc(#{percentage(1/3)} - 0.25rem)
    }

    &.is-2-3 {
      width: calc(#{percentage(2/3)} - 0.25rem)
    }
  }
}

.c-form__group--consent {
  font-size: 0.75rem;
  color: $color-dark-blue;

  label {
    display: flex;
  }
}

.c-form__label {
  font: 700 normal 0.75rem/1.25 $font-primary;
  text-transform: uppercase;
  color: $color-dark-blue;
  display: block;
}

.c-form__input,
.c-form__select {
  margin-top: 0.5rem;
  width: 100%;
  border-radius: 0.25rem;
  border: 1px solid $color-steel;
  padding: 1rem;
  background-color: #fff;

  &.wpcf7-not-valid {
    border-color: $color-error;
  }
}

.c-form__input {
  &::placeholder {
    color: $color-steel;
  }

  &:focus {
    outline-color: $color-dark-blue;
  }
}

.c-form__select-wrap {
  position: relative;
  margin-top: 0.5rem;

  &::before,
  &::after {
    @include ps();

    z-index: 0;
  }

  &::before {
    background-color: $color-white;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &::after {
    width: 0.5rem;
    height: 0.375rem;
    top: 50%;
    transform: translateY(-50%);
    right: calc(1rem + 0.5rem);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.4 6.1'%3E%3Cpath fill='%23002d53' d='M4.7 6.1L0 1.4 1.4 0l3.3 3.3L8 0l1.4 1.4z' data-name='Path 924'/%3E%3C/svg%3E");
  }
}

.c-form__select {
  position: relative;
  margin-top: 0;
  z-index: 1;
  appearance: none;
  background-color: transparent;
}

.c-form__checkbox {
  --size: 1.25rem;
  --size-inner: 1rem;

  flex: 1 0 var(--size);
  margin-right: 0.5rem;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  appearance: none;
  background-color: $color-white;
  position: relative;

  &::after {
    @include ps();
    @include anim($dur: 0.15s);

    top: calc((#{ var(--size) } - #{ var(--size-inner) }) / 2);
    left: calc((#{ var(--size) } - #{ var(--size-inner) }) / 2);
    width: var(--size-inner);
    height: var(--size-inner);
    border-radius: 50%;
    transform: scale(0);
    background-color: $color-dark-blue;
  }

  &:checked {
    &::after {
      transform: scale(1);
    }
  }

  @include bp($mobile-breakpoint) {
    --size: 1.75rem;
    --size-inner: 1.25rem;
  }
}

.c-form__submit {
  font-size: 0.875rem;
  padding: 1rem 1.5rem;
  cursor: pointer;
  border: none;
  display: block;

  &[disabled] {
    background-color: $color-steel;
    border-color: transparent
  }

  .submitting & {
    padding-right: 4rem;
  }

  @include bp($mobile-breakpoint) {
    width: 20rem;

    .submitting & {
      padding-right: 1.5rem;
    }
  }
}

.c-form__controls {
  @include fp(padding-top, 24px, 40px);
  @include fp(padding-bottom, 24px, 40px);
  @include fp(margin-top, 24px, 40px);

  border-top: 1px solid $color-steel;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;

  margin: {
    left: -2rem;
    right: -2rem;
  };
  background-color: $color-white;

  @include bp($mobile-breakpoint) {
    margin: {
      left: -5.5rem;
      right: -5.5rem;
    };
  }
}

.c-form__response {
  width: 100%;
}
