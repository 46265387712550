/* ==========================================================================
   #TEXT
   ========================================================================== */

.c-text--outline {
  font-family: $font-headings;
  color: transparent;
  font-weight: 700;
  text-transform: uppercase;
  -webkit-text-stroke: 1px $color-dark-blue;

  .c-intro--full &,
  .c-intro--has-dark-bg &,
  .c-intro--archive &,
  .c-widget__headline--story-intro &,
  .c-widget--story-chapter &,
  .c-widget--has-dark-bg:not(.c-widget--fact-grid) &,
  .c-fact--grid:not(.c-fact--light) &,
  .c-tease__content--overlay .c-tease__title--overlay &,
  &.c-text--outline-light {
    -webkit-text-stroke: 1px $color-white;
  }

  .c-widget--has-font-color & {
    -webkit-text-stroke: 1px var(--font-color);
  }

  .c-intro--facts-text & {
    -webkit-text-stroke: var(--main-color);
  }

  .c-fact--height-large & {
    font-size: 0.75em;
    line-height: 0.75em;
  }
}

.c-text--multiply {
  color: $color-white;
  mix-blend-mode: difference;
  display: block;
  z-index: 1;

  @include bp($mobile-breakpoint) {
    display: initial;
  }
}

.c-text--meta {
  color: $color-dark-blue;
  margin-bottom: 2rem;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 1;
  text-transform: uppercase;

  @include bp($mobile-breakpoint) {
    font-size: 0.8125rem;
  }
}

.c-text--label {
  display: block;
  margin: 0;
  color: $color-dark-blue;
  font: 500 0.75rem $font-primary;
  letter-spacing: 1.8px;
  text-transform: uppercase;
  margin-bottom: 0.75em;

  .c-intro--facts-labeled &,
  .c-grid--has-bg &,
  .c-intro--archive &,
  .c-widget--has-dark-bg & {
    color: $color-white;
  }

  .c-widget--has-font-color & {
    color: var(--font-color);
  }

  @include bp($mobile-breakpoint) {
    font-size: 1rem;
    letter-spacing: 2.4px;
  }
}

.c-text--squared-wrap {
  width: 100%;
  display: grid;
  grid-template-areas:
    'square rest'
    'square rest';

  .c-intro__content-text & {
    font-size: 1.25rem;
  }

  .c-intro--facts-text & {
    margin-bottom: 2rem;
  }

  @include bp($mobile-breakpoint) {
    display: flex;
    align-items: center;

    .c-intro__content-text & {
      font-size: 0.9em;
    }
  }

  &.c-text--squared-wrap-multiline {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 1;

    @include bp($mobile-breakpoint) {
      flex-direction: row;
      justify-content: flex-end;

      span {
        text-align: right;
      }
    }

    .c-text--squared {
      margin: 1.25rem 0;

      @include bp($mobile-breakpoint) {
        margin: 0 1.5rem;
      }

      + span {
        margin-top: 0;

        @include bp($mobile-breakpoint) {
          text-align: left;
          margin-right: 5%;
        }
        @include bp(wide) {
          margin-right: 10%;
        }
      }
    }
  }
}

.c-text--squared {
  @include fp(width, 64px, 96px);
  @include fp(height, 64px, 96px);
  @include fp(flex-basis, 64px, 96px);

  grid-area: square;
  position: relative;
  padding: 1rem;
  background-color: $color-dark-blue;
  color: var(--bg-color);
  display: inline-flex;

  &.c-text--squared--large {
    @include fp(width, 120px, 128px);
    @include fp(height, 120px, 128px);
    @include fp(flex-basis, 120px, 128px);
  }

  &.c-text--squared--invert {
    background-color: $color-white;
    background-color: var(--font-color);
    color: var(--bg-color);
  }

  &::before {
    @include ps();

    padding-bottom: 100%;
    top: 0;
    left: 0;
    height: 0;
    width: 100%;
  }

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  margin-right: 1rem;

  + span {
    margin-top: 1.125rem;
  }

  .c-intro--facts-text & {
    background-color: var(--main-color);
  }

  .c-intro__headline & {
    flex-grow: 1;
    flex-shrink: 0;
  }

  @include bp($mobile-breakpoint) {
    + span {
      margin-top: 0;
      margin-right: 0.5rem;
    }

    .c-intro--facts-text & {
      + span {
        margin-top: 1.75rem;
      }
    }
  }
}

.c-text--sticky {
  position: relative;
  display: inline-block;
  line-height: 0.98;

  .c-intro--facts-parallax & {
    z-index: var(--text-index);
  }

  @media (max-width: 1023px) {
    transform: none !important;
    z-index: 0 !important;
    mix-blend-mode: revert;
  }
}

.c-text--sticky-clone {
  visibility: hidden;
}

.c-text--lead {
  @include fp(font-size, 20px, 28px);
  @include fp(line-height, 30px, 44px);
  line-height: 1;
  font-weight: 600;
  max-width: 56.25rem;

  .c-text--label + &,
  .c-text--label ~ & {
    margin-top: 1.5rem;
  }
}

h2 .c-text--lead,
h3 .c-text--lead,
h4 .c-text--lead {
  line-height: 1;
}

.c-text--semibold {
  font-weight: 600;
}

.c-text--offset {
  @include fp(margin-left, 32px, 128px);
}

.c-text--arrow {
  font-size: 1.125em;
}

.c-text--content {
  @include fp(font-size, 16px, 18px);
  @include fp(line-height, 24px, 26px);

  font-weight: 500;
}

.c-text--group {
  line-height: 1;
  // # TODO: check for bug on other occurences
  width: 100%;
  display: block;
}

.c-text--widget {
  font-size: 0.875rem;
}

.c-text--two-col {
  @include bp($mobile-breakpoint) {
    column-count: 2;
    column-gap: 1rem;
  }
}
