/* ==========================================================================
   FACTS AND SINGLE FACT
   ========================================================================== */

.c-facts:not(.c-facts--grid) {
  @include fp(padding, 32px, 36px);
  @include fp(padding-left, 24px, 32px);
  @include fp(padding-right, 24px, 32px);

  position: relative;
  z-index: 2;
  border-radius: 0.25rem;
  box-shadow: 0 0 1.5rem 1.5rem $color-steel;
  background-color: $color-white;
  border: 1px solid $color-steel;

  .c-intro--facts-text & {
    background-color: var(--main-color);
    border-color: var(--main-color);
  }
}

.c-facts--grid {
  background-color: transparent;
}
.c-facts--grid-mobile .c-masonry__item {
  padding: 0;
}

// List
.c-facts__list {
  display: flex;
  flex-flow: column wrap;
  list-style: none;
  margin: 0;
  padding: 0;

  @include bp($mobile-breakpoint) {
    flex-flow: row nowrap;
    justify-content: space-around;
  }
}

.c-facts__list-item {
  margin: 0;
}

// Facts Separator
.c-facts__separator {
  margin: 1rem 0 2rem;
  background-color: $color-steel;
  height: 1px;
  width: 100%;

  @include bp($mobile-breakpoint) {
    margin: 0 2rem;
    height: initial;
    width: 1px;
  }
}

// Single Fact
.c-fact {
  color: var(--font-color, $color-dark-blue);
  display: flex;
}

.c-fact--grid {
  position: relative;
  color: $color-white;
  border: 1px solid transparent;
  border-radius: 0.5rem;
  hyphens: none;
  box-sizing: border-box;
  padding: 1.5rem;

  @include bp($mobile-breakpoint) {
    padding: 2.5rem;
  }
}

.c-fact--width-full {
  width: 100%;
}

.c-fact--height-large {
  min-height: 25rem;
}

.c-fact--height-extra-large {
  min-height: 35rem;
}

.c-fact--light {
  background-color: $color-white;
  color: $color-dark-blue;
}

.c-fact__icon {
  fill: currentColor;
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
}

.c-fact__icon--grid {
  @include fp(width, 64px, 104px);
  @include fp(height, 64px, 104px);

  position: absolute;

  margin-right: 0;
  opacity: 0.25;
  right: 1rem;
  top: 1rem;

  .c-fact--height-large & {
    @include fp(width, 104px, 180px);
    @include fp(height, 104px, 180px);
  }

  &.bottom-right {
    margin-top: auto;
    top: initial;
    bottom: 1rem;
  }

  @include bp($mobile-breakpoint) {
    right: 2.5rem;
    top: 2.5rem;

    .c-masonry__item--width-half & {
      top: 0.5rem;
    }

    &.bottom-right {
      top: initial;
      bottom: 2.5rem;

      .c-masonry__item--width-half & {
        bottom: 0.5rem;
      }
    }

    .c-fact--grid-width-third &,
    .c-fact--grid-width-half & {
      top: 1rem;
    }
  }

  &.center-right {
    top: 50%;
    transform: translateY(-50%);
  }
}

.c-fact__value-wrap {
  display: block;
  font: bold 1.25rem/1 $font-headings;
  text-transform: uppercase;

  .c-fact--grid & {
    @include fp(font-size, 22px, 48px);
  }

  @include bp(wide) {
    .c-fact--grid & {
      @include fp(font-size, 40px, 60px);
    }

    .c-masonry__item--width-third & {
      @include fp(font-size, 24px, 48px);
    }
  }
}

.c-fact__value-wrap--relation {
  @include bp($mobile-breakpoint) {
    display: flex;
    align-items: center;
  }
}

.c-fact__suffix {
  .c-fact--grid & {
    font-size: 0.5em;
    -webkit-text-stroke: 1px $color-white;
    color: transparent;
  }

  .c-fact--light & {
    -webkit-text-stroke: 1px $color-dark-blue;
  }

  .c-fact__value-wrap--relation & {
    font-size: 0.375em;
    -webkit-text-stroke: initial;
    color: currentColor;
    font-weight: 500;
    font-family: $font-primary;
    margin: 0 0.5rem;
  }
}

.c-fact__text {
  display: flex;
  font-size: 0.75rem;
  font-family: $font-primary;
  height: 100%;
  flex-direction: column;

  > p {
    margin: 0;
  }

  .c-fact--grid & {
    font-family: $font-headings;
    text-transform: uppercase;

    @include fp(font-size, 22px, 48px);
    @include fp(line-height, 22px, 48px);
  }

  .c-masonry__item--width-third & {
    @include fp(font-size, 24px, 40px);
    @include fp(line-height, 24px, 40px);
  }

  .c-fact--grid-width-full & {
    @include bp($mobile-breakpoint) {
      margin-left: 2rem;
    }
  }

  @include bp-down($mobile-breakpoint) {
    hyphens: auto;
  }

  @include bp($mobile-breakpoint) {
    max-width: 18ch;

    .c-fact--grid & {
      max-width: initial;
    }
  }

  @include bp(wide) {
    .c-fact--grid & {
      @include fp(font-size, 32px, 60px);
      @include fp(line-height, 32px, 60px);
    }

    .c-masonry__item--width-third & {
      @include fp(font-size, 24px, 40px);
      @include fp(line-height, 24px, 40px);
    }
  }
}

.c-fact__content {
  display: flex;
  flex-direction: column;

  .c-fact--grid-width-full & {
    @include bp($mobile-breakpoint) {
      flex-direction: row;
      align-items: center;
    }
  }

  @include bp-down($mobile-breakpoint) {
    word-break: break-word;
  }
}

.c-fact__label {
  font-family: $font-primary;
  font-weight: 500;
  text-transform: none;
  font-size: 0.875rem;
  line-height: 1;

  .c-fact--height-large & {
    margin-top: auto;
  }
}
