/* ==========================================================================
   #SEARCH
   ========================================================================== */

.c-search {
  display: none;
  width: calc(100% - #{$global-logo-size} - 2rem);
  position: absolute;
  left: $global-logo-size + 1rem;
  z-index: 1;

  &.is-active {
    display: block;
  }

  @include bp($mobile-breakpoint) {
    position: relative;
    left: initial;
    width: 19rem;
  }
}

.c-search__form-group {
  background-color: $color-white;
  border: 1px solid $color-steel;
  border-radius: $global-radius;
  padding: 0.75rem;
  display: flex;
  align-items: center;

  &:focus-within {
    border-color: $color-dark-blue;
  }
}

.c-search__input {
  border: none;
  margin-left: 0.5rem;
  width: calc(100% - 1.25rem - 1.25rem);

  &::placeholder {
    color: $color-steel;
  }

  &:focus {
    outline: none;
  }
}

.c-search__btn {
  background-color: transparent;
}

.c-search__btn--submit {
  order: -1;
  width: 1.25rem;
  height: 1.25rem;

  @include bp($mobile-breakpoint) {
    width: 1.375rem;
    height: 1.375rem;
  }
}

.c-search__btn--close {
  width: initial;
  height: initial;
  font-size: 2.5rem;
  line-height: 0;
  color: $color-steel;

  &:hover,
  &:focus {
    color: $color-link;
  }

  &:focus {
    outline: none;
  }
}
