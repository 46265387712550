/* ==========================================================================
   CONTACT FORM 7 Styles
   ========================================================================== */


.c-form .wpcf7 {
  .wpcf7-list-item {
    margin: 0;
  }

  .wpcf7-not-valid-tip {
    font-size: 0.75rem;
  }

  form.wpcf7-form .wpcf7-response-output {
    @include fp(margin-top, 24px, 40px);

    transform: translateY(1rem);
    display: flex;
    align-items: center;
    padding: 1rem;
    font-size: 0.75rem;
    width: 100%;
    max-width: calc(100% - 2rem);
    color: $color-white;
    margin: {
      left: 1rem;
      right: 1rem;
    };
    border-radius: 0.25rem;
    border: 1px solid $color-steel;
    box-shadow: 0 1.5rem 1.5rem $color-steel;

    &::before {
      @include fp(margin-right, 16px, 32px);

      content: '';
      display: block;
      width: 2rem;
      height: 2rem;
      flex: 1 0 2rem;
      background: transparent no-repeat 50% 50% / 2rem 2rem;
    }

    @include bp($mobile-breakpoint) {
      max-width: 45rem;
      margin: {
        left: auto;
        right: auto;
      };

      &::before {
        flex: 0 0 2rem;
      }
    }
  }

  form.wpcf7-form.init .wpcf7-response-output,
  form.wpcf7-form.submitting .wpcf7-response-output {
    display: none;
  }

  form.sent .wpcf7-response-output {
    background-color: $color-success; /* Green */

    &::before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cg data-name='Group 3125'%3E%3Cg data-name='Group 3124'%3E%3Cg fill='%23fff' stroke='rgba(0,45,83,0.15)' data-name='Rectangle 1474'%3E%3Crect width='30' height='30' stroke='none' rx='15'/%3E%3Crect width='29' height='29' x='.5' y='.5' fill='none' rx='14.5'/%3E%3C/g%3E%3C/g%3E%3Cpath fill='none' stroke='%2352cca3' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M8.5 13.889l4.882 4.882 8-8' data-name='Path 927'/%3E%3C/g%3E%3C/svg%3E");
    }
  }

  form.failed .wpcf7-response-output,
  form.aborted .wpcf7-response-output {
    background-color: $color-error; /* Red */

    &::before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cg data-name='Group 3125'%3E%3Cg data-name='Group 3124'%3E%3Cg fill='%23fff' stroke='rgba(0,45,83,0.15)' data-name='Rectangle 1474'%3E%3Crect width='30' height='30' stroke='none' rx='15'/%3E%3Crect width='29' height='29' x='.5' y='.5' fill='none' rx='14.5'/%3E%3C/g%3E%3C/g%3E%3Cg fill='none' stroke='%23ff6767' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' data-name='Group 3134' opacity='.996'%3E%3Cpath d='M11.358 18.771l8-8' data-name='Path 927'/%3E%3Cpath d='M11.359 10.772l8 8' data-name='Path 932'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    }
  }

  form.spam .wpcf7-response-output,
  form.invalid .wpcf7-response-output,
  form.unaccepted .wpcf7-response-output {
    background-color: $color-warning; /* Orange */

    &::before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cg data-name='Group 3667'%3E%3Cg data-name='Group 3124'%3E%3Cg fill='%23fff' stroke='rgba(0,45,83,0.15)' data-name='Rectangle 1474'%3E%3Crect width='30' height='30' stroke='none' rx='15'/%3E%3Crect width='29' height='29' x='.5' y='.5' fill='none' rx='14.5'/%3E%3C/g%3E%3C/g%3E%3Cpath fill='%23ff9c67' d='M13.82 11.4h1.92V22h-1.92zm.96-2.04a1.28 1.28 0 01-.93-.36 1.181 1.181 0 01-.37-.88 1.213 1.213 0 01.37-.89 1.261 1.261 0 01.93-.37 1.3 1.3 0 01.93.35 1.149 1.149 0 01.37.87 1.237 1.237 0 01-.37.91 1.261 1.261 0 01-.93.37z' data-name='Path 1053'/%3E%3C/g%3E%3C/svg%3E");
    }
  }

  .ajax-loader {
    position: relative;
    transform: translateX(-100%);
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background-color: $color-white;
    animation: dot-flashing 0.75s 1s infinite $global-timing-func alternate;
    margin: 0;
    left: -2rem;

    &::before,
    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: inherit;
    }

    &::before {
      left: -0.75rem;
      animation: inherit;
      animation-delay: 0.5s;
    }

    &::after {
      left: 0.75rem;
      animation: inherit;
      animation-delay: 1.5s;
    }

    @include bp($mobile-breakpoint) {
      left: -3rem;
    }
  }
}

@keyframes dot-flashing {
  0% {
    background-color: $color-white;
  }
  50%,
  100% {
    background-color: rgba(255,255,255, 0.125);
  }
}
