/* ==========================================================================
   #BUTTONS
   ========================================================================== */

/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */

/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */

.c-btn {
  display: inline-block; /* [1] */
  vertical-align: middle; /* [2] */
  font: inherit; /* [3] */
  text-align: center; /* [4] */
  margin: 0; /* [4] */
  cursor: pointer; /* [5] */
  padding: 0.5rem 1rem;
  line-height: 1.125;
  transition: $global-transition;
  border-radius: $global-radius;
  background-color: transparent;
  font-weight: 500;
  text-decoration: none;

  &.disabled {
    pointer-events: none;
    cursor: not-allowed;
    background-color: $color-dark-grey;
  }

  svg {
    transition: $global-transition;
  }
}

/* Style variants
   ========================================================================== */

.c-btn--primary {
  background-color: $color-link;

  &,
  &:hover,
  &:active,
  &:focus {
    text-decoration: none; /* [4] */
    color: $color-white;
  }

  &:hover,
  &:focus {
    background-color: $color-hover;
  }
}

.c-btn--is-icon {
  width: 1.25rem;
  height: 1.25rem;
  padding: 0;
  border: none;
  fill: $color-dark-blue;

  &.c-btn--primary {
    fill: $color-white;

    &:hover {
      fill: $color-dark-blue;
    }
  }

  svg {
    width: 100%;
    height: 100%;
  }
}

.c-btn--burger {
  color: $color-dark-blue;
  background: transparent;
  padding: 0;
  border: none;
  cursor: pointer;
  display: block;
  height: 1.75rem;
  position: relative;
  width: 1.75rem;

  span {
    background-color: currentColor;
    display: block;
    height: 2px;
    left: calc(50% - 8px);
    position: absolute;
    transform-origin: center;
    transition: $global-transition;
    width: 1em;
    &:nth-child(1) {
      top: calc(50% - 6px);
    }
    &:nth-child(2) {
      top: calc(50% - 1px);
    }
    &:nth-child(3) {
      top: calc(50% + 4px);
    }
  }

  // Modifers
  &.menu-open {
    span {
      &:nth-child(1) {
        transform: translateY(5px) rotate(45deg);
      }
      &:nth-child(2) {
        opacity: 0;
      }
      &:nth-child(3) {
        transform: translateY(-5px) rotate(-45deg);
      }
    }
  }
}

.c-btn--is-icon-globe {
  @include bp-down($mobile-breakpoint) {
    display: none;

    .menu-open & {
      display: block;
    }
  }
}

.c-btn--is-icon-search {
  @include bp-down($mobile-breakpoint) {
    .menu-open & {
      display: none;
    }
  }
}

.c-btn--steel,
.c-btn--transparent,
.c-btn--white {
  color: $color-dark-blue;
  background-color: $color-steel;
  border: 1px solid $color-steel;

  &:hover {
    background-color: $color-dark-blue;
    color: $color-white;
  }

  .c-widget--has-dark-bg & {
    &:hover {
      background-color: rgba($color-white, 0.85);
      color: $color-dark-blue;
    }
  }
}

.c-btn--grey {
  background-color: $color-grey;
}

.c-btn--white {
  background-color: $color-white;
}

.c-btn--transparent {
  background-color: transparent;
}

.c-btn--round {
  @include anim();

  width: 2.375rem;
  height: 2.375rem;
  border: 1px solid transparent;
  padding: 0.5rem;

  &:hover,
  &.is-active {
    background-color: $color-white;
    border-color: $color-steel;
  }

  &:focus {
    outline: none;
    border-color: $color-dark-blue;
  }

  @include bp($mobile-breakpoint) {
    padding: 0.75rem;
    width: 3rem;
    height: 3rem;
  }
}

.c-btn--filter {
  display: flex;
  align-items: center;

  &.is-active {
    color: $color-white;
    background-color: $color-dark-blue;
  }

  .c-grid__btngroup--light & {
    background-color: $color-white;
    color: $color-dark-blue;
  }
}

.c-btn__icon {
  @include anim($prop: transform);

  display: block;
  margin-left: 0.5rem;

  .c-btn:hover &,
  .c-btn.is-active & {
    transform: scale(-1) translateY(-2px);
  }
}

.c-btn--has-arrow {
  display: flex;
}

.c-btn--light {
  color: $color-white;
  border-color: $color-white;

  &:hover {
    background-color: $color-white;
    color: $color-dark-blue;
  }
}

.c-btn--play {
  display: flex;
  align-items: center;

  svg {
    width: 1rem;
    height: 1rem;
    margin-right: 1rem;
    fill: currentColor;
  }
}

.c-btn--clipboard {
  position: relative;
}

.c-btn--arrow-left {
  svg {
    width: 0.8em;
    height: 0.8em;
    transform: rotateZ(180deg);
    transform-origin: center;
    fill: currentColor;
  }
}

.c-btn--arrow-right {
  svg {
    width: 0.8em;
    height: 0.8em;
    transform-origin: bottom;
    margin-left: 0.125rem;
    fill: currentColor;
  }
}

.c-btn--arrow-top-right {
  svg {
    width: 0.8em;
    height: 0.8em;
    transform: rotate(-45deg);
    transform-origin: bottom;
    margin-left: 0.5rem;
    fill: currentColor;
  }
}
.c-btn--outlined {
  background-color: transparent;
  color: $color-dark-blue;
  border: 1px solid $color-dark-blue;
  svg {
    fill: currentColor;
  }
  &:hover,
  &.is-active {
    background-color: $color-dark-blue;
    color: #fff;
    svg {
      fill: currentColor;
    }
  }
  &:focus {
    outline: none;
    border-color: $color-light-blue;
  }
}

.c-btn--outlined-light {
  background-color: transparent;
  color: #fff;
  border: 1px solid #fff;
  svg {
    fill: currentColor;
  }
  &:hover,
  &.is-active {
    background-color: #fff;
    color: $color-dark-blue;
    svg {
      fill: currentColor;
    }
  }
  &:focus {
    outline: none;
    border-color: #fff;
  }
}

.c-btn--turquoise {
  background-color: $color-up-turquoise;
  border: 1px solid $color-up-turquoise;
  color: #fff;
  svg {
    fill: currentColor;
  }
  &:hover,
  &.is-active {
    color: #fff;
    background-color: transparent;
  }
}

.c-btn--pink {
  background-color: $color-up-pink;
  border: 1px solid $color-up-pink;
  color: $color-dark-blue;
  svg {
    fill: currentColor;
  }
  &:hover,
  &.is-active {
    color: #fff;
    border-color: $color-dark-blue;
    background-color: $color-dark-blue;
  }
}

/* Size variants
   ========================================================================== */

.c-btn--small {
  padding: 0.25rem 0.5rem;
}

.c-btn--large {
  padding: 0.75rem 1.5rem;
}

.c-btn--full-width {
  width: 100%;
}

.c-btn__copy-text {
  @include anim();

  position: absolute;
  left: calc(100% + 1rem);
  top: 50%;
  transform: translate(-1rem, -50%);
  opacity: 0;
  visibility: hidden;
  background-color: $color-dark-blue;
  color: #fff;
  padding: 0.375rem;
  border-radius: 0.25rem;
  white-space: nowrap;
  font-size: 0.875rem;

  &:before {
    @include ps();

    top: 50%;
    left: 0;
    display: block;
    width: 0;
    height: 0;
    border: 0.375rem solid transparent;
    transform: translate(-100%, -50%);
    border-right-color: $color-dark-blue;
  }

  .is-copied & {
    opacity: 1;
    visibility: visible;
    transform: translate(0%, -50%);
  }
}
