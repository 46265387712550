/* ==========================================================================
   IMAGE TEXT
   ========================================================================== */

.c-image-text {
  position: relative;
  z-index: 2;
  background-color: $color-white;
  padding-bottom: 4.5rem;

  @include bp($mobile-breakpoint) {
    padding-bottom: 0;
  }
}

.c-image-text__inner {
  @include bp($mobile-breakpoint) {
    display: flex;
    align-items: center;

    .c-image-text--is-right & {
      flex-direction: row-reverse;
    }

    .c-image-text--hero-tags & {
      align-items: initial;
    }
  }
}

.c-image-text__image {
  position: relative;
  margin: 0 auto;
  background: no-repeat 50% 50% / cover;
  max-width: 100%;
  width: 100%!important;
  height: 100%;
  z-index: 1;

  @include bp($mobile-breakpoint) {
    margin: initial;
    width: 50%!important;

    .c-image-text--is-portrait & {
      width: 33%!important;
    }
  }
}

.c-image-text__content {
  flex: 1 0 auto;
  padding: 0 2rem;
  color: $color-dark-blue;

  .c-widget--has-font-color & {
    color: var(--font-color)
  }

  @include bp($mobile-breakpoint) {
    padding: 0;
    width: 50%;
    max-width: initial;

  }
}

.c-image-text__content-inner {
  margin-top: 2rem;

  @include bp($mobile-breakpoint) {
    padding: 2rem 0;

    margin: 0 2rem 0 auto;

    .c-image-text--is-left & {
      margin: 0 0 0 2rem;
    }

  }

  @include bp(xlarge) {
    padding: 0;
  }
}
