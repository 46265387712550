/* ==========================================================================
   CONTENT
   ========================================================================== */

.c-content {
  @include fp(margin-top, 24px, 48px);

  @include bp($mobile-breakpoint) {
    display: flex;
    width: 100%;
  }
}

.c-content__text,
.c-content__video {
  @include bp($mobile-breakpoint) {
    width: percentage(5/12);
  }
}

.c-content__text {
  font-size: 0.875rem;
  line-height: 1.57;
}

.c-content__link {
  font-weight: 600;
}

.c-content__video {
  margin-top: 4rem;

  @include bp($mobile-breakpoint) {
    margin-top: 0;
    margin-left: percentage(2/12);
  }
}
