/* ==========================================================================
   NOTICE
   ========================================================================== */

.c-notice {
  @include fp(margin-top, 40px, 96px);
  @include fp(padding-top, 16px, 32px);
  @include fp(padding-bottom, 16px, 32px);
  @include fp(padding-left, 24px, 48px);
  @include fp(padding-right, 24px, 48px);

  background-color: $color-grey;
  border: 1px solid $color-steel;
  border-radius: 0.25rem;
  box-shadow: 0 1.5rem 1.5rem rgba($color-dark-blue, 0.2);

  p {
    margin: 0;
  }
}
