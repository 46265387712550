/* ==========================================================================
   FILES TABLE/GRID
   ========================================================================== */

.c-files {
  @include fp(padding-top, 60px, 120px);
  @include fp(padding-bottom, 32px, 56px);
}

.c-files__header {
  display: flex;
  align-items: center;
  padding: 0 2rem;

  @include bp($mobile-breakpoint) {
    padding: 0 1rem;
  }
}

.c-files__header--list {
  &.hide {
    display: none;
  }
}

.c-files__header-item {
  font: 700 0.75rem $font-primary;
  color: $color-dark-blue;
  padding: 0 2rem;
  flex: 1;

  &:first-child {
    width: calc(60% + 3rem);
    padding: 0;
    flex: 0 0 calc(60% + 3rem);
  }

  &:last-child {
    padding: 0;
    flex: 0;
  }
}

.c-files__view {
  @include fp(padding-top, 32px, 64px);
  @include fp(padding-bottom, 32px, 64px);

  padding-left: 1rem;
  padding-right: 1rem;

  @include bp($mobile-breakpoint) {
    padding-left: 0;
    padding-right: 0;
  }
}

.c-files__list {
  padding-top: 1.5rem;

  .c-files__header--list.hide + & {
    padding-top: 0;
  }
}

.c-files__list--grid {
  @include bp($mobile-breakpoint) {
    display: grid;
    grid-gap: 2.5rem;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
