/* ==========================================================================
   #TEASE
   ========================================================================== */

.c-tease {
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 1rem;
}

.c-tease--post {
  display: flex;
  flex-flow: column wrap;
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem $color-steel;

  @include bp($mobile-breakpoint) {
    &.is-sticky {
      flex-flow: row nowrap;

      .c-post__related-posts & {
        flex-direction: column;
        width: calc(#{percentage(1/3)} - 1rem);
      }
    }
  }
}

.c-tease--post-half {
  @include bp($mobile-breakpoint) {
    width: calc(50% - 0.5rem);

    &:nth-child(even) {
      margin-left: 1rem;
    }
  }
}

.c-tease--inline-icon {
  @include fp(padding, 16px, 32px);

  display: flex;
  align-items: center;
  color: $color-dark-blue;
  background-color: $color-grey;
  border: 1px solid $color-steel;
  width: 100%;
  text-decoration: none;

  &:hover {
    color: #fff;
    background-color: $color-dark-blue;

    svg:last-of-type {
      transform: translateX(0.25rem);
    }
  }

  svg {
    fill: currentColor;

    &:first-of-type {
      width: 2rem;
      height: 1.5rem;
    }

    &:last-of-type {
      @include anim($prop: transform);

      flex: 1 0 1rem;
      width: 1rem;
      height: 1rem;
      margin-left: auto;
    }
  }

  @include bp($mobile-breakpoint) {
    width: calc(50% - 0.5rem);
    &:nth-child(even) {
      margin-left: 1rem;
    }

    svg {
      &:last-of-type {
        flex: initial;
      }
    }
  }
}

.c-tease--box {
  padding: 1rem;
  flex-wrap: wrap;
  width: calc(50% - 0.5rem);
  margin-left: 1rem;

  &:nth-child(odd) {
    margin-left: 0;
  }

  @include bp($mobile-breakpoint) {
    width: percentage(2/12);
  }
}

.c-tease--download-category {
  filter: drop-shadow(0 8px 16px rgba($color-black, 0.4));

  will-change: filter;
  transition: filter 0.3s $global-timing-func;

  &:hover {
    filter: drop-shadow(0 4px 8px rgba($color-black, 0.5));
  }
}

.c-tease--event,
.c-tease--webcast {
  background-color: $color-grey;
  border: 1px solid $color-steel;
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem $color-steel;
}

.c-tease--event {
  background-color: $color-white;
  display: flex;
  flex-flow: column wrap;
}

.c-tease--event .c-tease__banner {
  position: relative;
}
.c-tease--event .c-tease__banner img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.c-tease--webcast {
  width: 100%;

  @include bp($mobile-breakpoint) {
    display: grid;
    grid-template-areas:
      'header video'
      'content video';
    grid-template-columns: percentage(1/3) percentage(2/3);
    grid-template-rows: auto 1fr;
  }
}

.c-tease--contact-person,
.c-tease--upscaler {
  position: relative;

  &::after {
    @include ps();

    z-index: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-image: linear-gradient(
      to top,
      rgba($color-dark-blue, 0.5),
      transparent
    );
  }
}

// Banner (Event)
.c-tease__banner {
  @include fp(height, 120px, 180px);

  background: $color-dark-blue
    radial-gradient(50% 50% at 50% 50%, #3f5b7c 0%, #003259 100%);
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 1rem;

  @include bp($mobile-breakpoint) {
    padding: 1rem 2rem;
  }

  svg {
    @include fp(width, 82px, 82px);
    @include fp(height, 24px, 24px);

    fill: #cfd2d7;
  }
}

// Header
.c-tease__header {
  display: flex;
  background-color: $color-white;
  border-bottom: 1px solid $color-steel;
  padding: 1rem;

  @include bp($mobile-breakpoint) {
    padding: 1rem 2rem;
  }
}

.c-tease__header--webcast {
  padding: 1rem;
  display: flex;
  grid-area: header;
  align-self: flex-start;
}

// Image
.c-tease__image {
  background: transparent no-repeat 50% 50% / cover;

  .c-tease--post & {
    order: -1;
  }

  .c-tease--contact-person &,
  .c-tease--upscaler & {
    height: 100%;
  }

  @include bp($mobile-breakpoint) {
    .c-tease--post.is-sticky & {
      order: initial;
      width: 50%;

      .c-post__related-posts & {
        width: 100%;
        order: -1;
      }
    }
  }
}

// Content
.c-tease__content {
  position: relative;
  display: block;

  .c-tease--post & {
    background-color: $color-white;
  }

  .c-tease--download-category & {
    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 300%;
      background: linear-gradient(
        rgba($color-dark-blue, 0.3) 0%,
        rgba($color-dark-blue, 0.8) 100%
      );
      will-change: height;
      transition: height 0.3s $global-timing-func;
    }

    &.c-tease__content--no-overlay {
      &:hover {
        &:after {
          height: 0;
        }
      }
      &:after {
        height: 0;
      }
    }

    &:hover {
      &:after {
        height: 100%;
      }
    }
  }

  .c-tease--post & {
    flex: 1;
    padding: 1rem 2rem;
    width: 100%;
    @include bp-down($mobile-breakpoint) {
      padding: 1rem 1rem;
    }
  }

  .c-tease--contact-person &,
  .c-tease--upscaler & {
    @include anim();

    transition-property: opacity, transform;
    z-index: 1;
    position: absolute;
    top: 2rem;
    left: 2rem;
    width: calc(100% - 4rem);
    height: calc(100% - 4rem);
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-end;
    color: $color-white;
    opacity: 0;
    transform: translateY(1rem);
  }

  .c-tease--contact-person:hover &,
  .c-tease--upscaler:hover & {
    opacity: 1;
    transform: translateY(0);
  }
}

.c-tease__content--webcast {
  background-color: $color-grey;
  grid-area: content;
  padding: 1.5rem 1rem 1rem;

  @include bp($mobile-breakpoint) {
    padding: 1.5rem 2rem 1rem;
    display: flex;
    flex-flow: column wrap;
  }
}

.c-tease__content--overlay {
  background-color: rgba($color-dark-blue, 0.65);

  .c-tease--contact-person &,
  .c-tease--upscaler & {
    transition-property: opacity, transform, visibility;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    padding: 2rem;
    opacity: 1;
    transform: translateY(0);
  }

  .c-tease--contact-person:hover &,
  .c-tease--upscaler:hover & {
    opacity: 0;
    transform: translateY(-1rem);
    visibility: hidden;
  }
}

.c-tease__content-link {
  text-decoration: none;
  display: flex;
  height: 100%;
  flex-flow: column wrap;

  &:hover {
    color: rgba($color-dark-blue, 0.8);
  }
}

.c-tease__content-link--social {
  color: $color-white;
}

.c-tease__content-link--event,
.c-tease__content-link--webcast {
  padding: 1.5rem 1rem 1rem;
  height: initial;
  flex: 1;

  @include bp($mobile-breakpoint) {
    padding: 1.5rem 2rem 1rem;
  }
}

.c-tease__content-link--webcast {
  padding: 0;
}

.c-tease__image-link {
  display: block;
  position: relative;
}

.c-tease__image-link--video {
  svg {
    fill: #fff;
    position: absolute;
    z-index: 1;
    filter: drop-shadow(0 0.5rem 1.5rem $color-dark-blue);
    width: 4.5rem;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.c-tease__content-inner {
  box-sizing: border-box;
  width: 100%;

  .c-tease--download-category & {
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 1rem;
    z-index: 2;
  }
}

// Meta
.c-tease__meta {
  display: flex;
  flex-wrap: wrap;
  margin-top: auto;
  align-items: center;

  .c-tease--has-multiple-markets & {
    flex-wrap: wrap;
  }

  @include bp(xlarge) {
    flex-wrap: nowrap;
    align-items: center;
  }
}

.c-tease__meta--vertical {
  flex-flow: column wrap;
  margin-left: 1rem;

  .c-tease--event &,
  .c-tease--webcast & {
    color: $color-dark-blue;
  }

  .c-tease--event & {
    margin-left: revert;
    align-items: revert;
    gap: 0.5em;
  }

  .c-tease--webcast & {
    margin-top: 0.25rem;
  }
}

.c-tease__meta--event {
  font-size: 0.875rem;
  font-weight: 500;
  margin-top: revert;
  margin-bottom: auto;
  hyphens: none;

  svg {
    flex-shrink: 0;
  }
}

.c-tease__btn {
  margin-top: 1rem;
  width: 100%;

  span:last-child {
    margin-left: 1rem;
  }

  .c-tease--post & {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    margin-left: 0;
  }

  .c-tease--event & {
    margin-top: auto;
  }

  .c-tease__title--event + & {
    align-self: flex-start;
    margin: {
      left: initial;
      top: auto;
    }
  }

  @include bp($mobile-breakpoint) {
    margin-top: 0;
    margin-left: auto;
    width: initial;
  }

  @include bp(xlarge) {
    .c-tease--post & {
      margin-top: 0;
      margin-left: auto;
      font-size: 0.875rem;
    }
  }
}

.c-tease__btn--webcast {
  margin: {
    left: initial;
    top: auto;
  }
  align-self: flex-start;
}

.c-tease__headline {
  font: 700 1rem $font-primary;
  text-transform: none;
  margin: 0;

  .c-tease__date ~ & {
    margin-top: 0.5rem;
  }
}

.c-tease__date,
.c-tease__preview {
  font-size: 0.875rem;
}

.c-tease__preview {
  margin-top: 0.5rem;

  .c-tease--webcast & {
    margin-bottom: 1rem;
    margin-top: 0;
  }
}

// Title
.c-tease__title {
  .c-tease--download-category & {
    @include fp(font-size, 1rem, 1.75rem);

    font-weight: 700;
    color: $color-white;
    margin-bottom: 0;
  }

  .c-tease--contact-person &,
  .c-tease--upscaler & {
    font: 700 1.75rem/1 $font-primary;
    color: $color-white;
    margin: 0;
    hyphens: none;
  }
}

.c-tease__title--overlay {
  .c-tease--contact-person &,
  .c-tease--upscaler & {
    @include fp(margin-bottom, 24px, 56px);

    text-transform: uppercase;

    * {
      margin: 0;
    }
  }

  .c-tease--contact-person.is-portrait &,
  .c-tease--upscaler.is-portrait & {
    @include fp(margin-bottom, 40px, 80px);
  }
}

.c-tease__title--event {
  font: 700 1rem/1.5 $font-primary;
  margin-bottom: 0;
}

.c-tease__title--inline-icon {
  margin-left: 1rem;
  font: 500 0.875rem/1.5 $font-primary;

  .c-tease--box & {
    @include fp(font-size, 10px, 14px);

    margin-left: 0;
    display: block;
    hyphens: none;
    width: percentage(3/4);

    @include bp($mobile-breakpoint) {
      width: percentage(1/2);
    }
  }
}

.c-tease__subtitle {
  .c-tease--download-category & {
    @include fp(font-size, 0.75rem, 0.875rem);

    margin-bottom: 0;
    color: $color-white;
  }
}

// Time/Date
.c-tease__time {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  padding: 0.75rem 1rem 0.5rem;
  border: 1px solid $color-steel;
  border-radius: 0.25rem;
  background-color: $color-grey;
  color: $color-dark-blue;
}

.c-tease__time-day {
  @include fp(font-size, 24px, 56px);

  font-weight: 700;
  line-height: 1;
}

.c-tease__time-month,
.c-tease__time-year {
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
}

.c-tease__cat-icon {
  padding: 1.125rem 0.75rem;
  border: 1px solid $color-steel;
  border-radius: 0.25rem;
  background-color: $color-grey;
  display: flex;
  align-items: center;

  svg {
    width: 2.5rem;
    height: 1.75rem;
  }
}

.c-tease__video {
  position: relative;
  border-top: 1px solid $color-steel;

  @include bp($mobile-breakpoint) {
    grid-area: video;
    border-left: 1px solid $color-steel;
  }
}

.c-tease__video-trigger {
  @include fp(width, 40px, 72px);
  @include fp(height, 40px, 72px);

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;

  svg {
    fill: $color-white;
    filter: drop-shadow(0 0.25rem 1.5rem $color-dark-blue);
  }
}

.c-tease__trigger {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  z-index: 1;

  &:focus {
    outline: none;
  }
}

.c-tease__contact-group {
  z-index: 2;
  margin-top: 0.5rem;
}

.c-tease__button-icon {
  background-color: $color-white;
  border-radius: 50%;
  width: 2.25rem;
  height: 2.25rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.375rem;

  svg {
    @include anim($prop: background-color);

    width: 1rem;
    height: 1rem;
  }

  &:hover {
    background-color: $color-dark-blue;

    svg {
      fill: $color-white;
    }
  }
}

.c-tease__content-grp {
  width: 100%;
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
}
