/* ==========================================================================
   CONTACT POPUP
   ========================================================================== */

.c-contact {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 201;
}

.c-contact__trigger {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  z-index: 1;
  width: 3rem;
  height: 3rem;
  background-color: $color-dark-blue;
  border: 1px solid rgba($color-grey, 0.15);

  &::before {
    @include ps();
    @include anim();

    position: absolute;
    border-radius: $global-radius;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 1rem rgba($color-white, 0.15);
    opacity: 0;
  }

  svg {
    @include anim();

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1.25rem;
    height: 1.25rem;
    opacity: 1;
    stroke: #fff;
  }

  svg:last-of-type {
    opacity: 0;
  }

  &.is-active,
  &:hover {
    &::before {
      opacity: 1;
    }
  }

  &.is-active {
    svg {
      width: 1.125rem;
      height: 1.125rem;
    }

    svg:first-of-type {
      opacity: 0;
    }

    svg:last-of-type {
      opacity: 1;
    }
  }

  &:focus {
    outline: none;
  }

  @include bp($mobile-breakpoint) {
    right: 3rem;
    bottom: 3rem;
  }
}

.c-contact__filter-wrap {
  color: $color-steel;
  position: relative;
  cursor: pointer;
  stroke: currentColor;
  border: 1px solid currentColor;
  border-radius: $global-radius;

  &:focus-within {
    color: $color-dark-blue;
  }
}

.c-contact__filter-label {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
  display: flex;

  svg {
    width: 1rem;
    height: 1rem;
  }
}


.c-contact__filter-select {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0.5rem;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: 0.875rem;
  cursor: inherit;
  line-height: inherit;
  color: currentColor;

  &:focus {
    outline: none;
  }

  option {
    color: inherit;
  }
}

.c-contact__header-controls {
  position: relative;
  overflow: hidden;
  flex: 1;
  display: flex;
  flex-flow: column wrap;

  &:not(.c-contact__header-controls--has-filter) {
    flex-flow: row nowrap;
    width: 100%;
    position: initial;
    overflow: initial;

    .c-contact__share-btn {
      transform: translateY(110%);
    }

    &.is-expanded {
      .c-contact__share-btn {
        transform: translateY(-50%)
      }
    }
  }
}

.c-contact__header-control {
  @include anim();

  transition-property: opacity, transform;
  margin-left: 1rem;

  &:last-child {
    position: absolute;
    right: 0;
    line-height: 1.3;
    transform: translateY(100%);
  }

  .is-expanded & {
    transform: translateY(-100%);
    opacity: 0;

    &:last-child {
      transform: translateY(0);
      opacity: 1;
    }
  }
}

.c-contact__share-btn {
  display: none;

  margin-left: auto;
  background-color: transparent;

  &:hover {
    background-color: $color-dark-blue;
  }
}

.c-contact__body {
  height: 100%;
  overflow: hidden;
}

.c-contact-person__badge {
  position: absolute;
  top: 1.75rem;
  left: -0.5rem;
  width: 2rem;
  height: 2rem;
  border: 1px solid $color-steel;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-white;

  svg {
    width: 100%;
    height: 100%;
    stroke-width: 0.5;
  }
}
