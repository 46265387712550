/* ==========================================================================
   IMAGE
   ========================================================================== */

.c-img {
  position: relative;
  overflow: hidden;
  width: 100%;

  &:before {
    content: '';
    display: block;
  }
}

/* Style Variants
   ========================================================================== */

.c-img--inline {
  width: initial;
  height: auto;
  max-width: 100%;

  .ie & {
    width: auto;
  }

  &:before {
    display: none;
  }
}

.c-img--square {
  &:before {
    padding-bottom: 100%;
  }
}

.c-img--landscape {
  &:before {
    padding-bottom: percentage(9/16);
  }
}

.c-img--portrait {
  &:before {
    padding-bottom: percentage(3/2);
  }
}

.c-img--data {
  &:before {
    padding-bottom: var(--image-aspect-ratio);
  }
}

/* Background Image Styles
   ========================================================================== */

.c-img__placeholder,
.c-img__full {
  @include anim();
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: $color-steel no-repeat 50% 50% / cover;
  background-position: var(--c-img--position);
  opacity: 0;

  &[data-loaded='true'],
  .tns-item:last-child &{
    opacity: 1;
  }
}

.c-img__placeholder {
  @include anim();
  opacity: 1;
  transition-delay: 0.7s;
  transition-property: opacity;
  filter: blur(0.5vw);
  transform: scale(1.1);

  [data-loaded='true'] + &,
  .tns-item:last-child & {
    opacity: 0;
  }
}

.c-img__caption {
  background: linear-gradient(180deg, rgba(0, 45, 83, 0) 0%, #002D53 100%);
  bottom: 0;
  color: white;
  font-size: 0.75rem;
  padding: 8em 2em 2em;
  position: absolute;
  width: 100%;
}
