/* ==========================================================================
   #SEARCHWP
   ========================================================================== */

.searchwp-live-search-results {
  @extend .c-popup;

  transition-property: transform, opacity!important;
  display: block !important;
  top: calc(#{$global-logo-size} + 1rem)!important;
  box-shadow: 0 3rem 3rem $color-steel!important;
  width: initial!important;
  border-radius: 1.5rem!important;

  @include bp-down($mobile-breakpoint) {
    left: 1rem!important;
  }

  @include bp($mobile-breakpoint) {
    transform: translate(-4.5rem, 2rem) !important;
  }
}

.searchwp-live-search-results-showing {
  @extend .c-popup.is-active;

  @include bp($mobile-breakpoint) {
    transform: translate(-4.5rem, 0) !important;
    width: 100% !important;
  }
}
