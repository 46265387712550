/* ==========================================================================
   #PAGE
   ========================================================================== */

/**
 * Simple page-level setup.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 */

html {
  background: $color-bg;
  color: $color-text;
  font-family: $font-primary;
  font-size: 1rem; /* [1] */ /* 13px */
  line-height: 1.5; /* [1] */
  height: 100vh;
  height: var(--app-height);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  scroll-behavior: smooth;
  scroll-padding-top: 70px;
  @include bp-down($mobile-breakpoint) {
    &.menu-open {
      overflow: hidden;
    }
  }
}
