/* ==========================================================================
   #POPUP
   ========================================================================== */

.c-popup {
  @include anim();

  padding: 1.5rem 1rem;
  position: absolute;
  border-radius: 1.5rem;
  top: calc(#{$global-logo-size} + 1rem);
  left: 1rem;
  right: 1rem;
  max-width: 24rem;
  background-color: $color-white;
  border: 1px solid $color-steel;
  transform: translateY(2rem);
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 3rem 3rem $color-steel;

  &.is-active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }

  @include bp($mobile-breakpoint) {
    right: 1.5rem;
    left: initial;
    width: 100%;
  }

  @include bp($max-width-breakpoint) {
    right: 0;
  }
}

.c-popup--self-contained {
  transition-duration: 0.1s;
  position: relative;
  z-index: 2;
  top: initial;
  left: initial;
  right: initial;
  transform: initial;
  opacity: 1;
  visibility: visible;
  border-radius: 0;
  margin-top: 4rem;
  max-width: initial;

  &.is-active {
    border-radius: 1.5rem;
    opacity: 0;
    visibility: hidden;
  }

  @include bp($mobile-breakpoint) {
    max-width: 24rem;
    position: absolute;
    bottom: 4rem;
    left: 0;
    transform: translateX(calc(-100% + 1rem));

    &.is-active {
      transform: translate(calc(-100% + 1rem), 0);
      opacity: 1;
      visibility: visible;
    }
  }
}

.c-popup--self-contained-clone {
  @include anim($dur: 0.2s);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: percentage(3/4);
  z-index: 2;
  transform: translateY(100%);

  &.is-active {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    opacity: 1;
    visibility: visible;
  }

  &::after {
    @include ps();
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30%;
    background-image: linear-gradient(to top, #fff, transparent);
  }

  @include bp($mobile-breakpoint) {
    display: none;
  }
}

.c-popup--contact {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 30vh;
  padding-bottom: 4.5rem;
  max-width: initial;

  &.is-active {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  @include bp($mobile-breakpoint) {
    max-width: 24rem;
    left: initial;
    top: initial;
    bottom: 4rem;
    right: 4rem;
    padding-bottom: 2rem;

    &.is-active {
      border-bottom-left-radius: 1.5rem;;
      border-bottom-right-radius: 1.5rem;;
    }
  }
}





// Header
.c-popup__header {
  display: flex;
  align-items: center;
}

.c-popup__header--contacts {
  &:not(.c-popup__header--has-filter) {
    flex-flow: column wrap;
    position: relative;
    overflow: hidden;

    .c-popup__headline {
      width: 100%;
    }
  }
}

.c-popup__header--with-close {
  font: bold 1.5rem $font-headings;
  width: 100%;
  border: none;
  background-color: transparent;
  text-transform: uppercase;
  cursor: pointer;
  color: $color-dark-blue;

  &.is-active,
  &:focus {
    outline: none;
  }
}

.c-popup__close {
  margin-left: auto;
  background-color: #fff;
  border-radius: 50%;
  font-family: $font-primary;
  font-weight: normal;
  border: 1px solid $color-steel;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  font-size: 2rem;
  line-height: 1;
}

.c-popup__close--text {
  @include anim($prop: transform);

  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0.8;

  .is-active & {
    transform: rotate(45deg);
  }
}

.c-popup__headline {
  font-family: $font-headings;
  text-transform: uppercase;
  font-size: 1.125rem;
  color: $color-dark-blue;

  .c-contact__header-controls--has-filter & {
    margin-right: 1rem;
  }
}





// Body
.c-popup__body {
  margin-top: 1rem;

  .c-popup--self-contained-clone & {
    overflow-y: auto;
    height: 100%;
    padding-bottom: 7rem;
  }

  .c-popup--contact & {
    position: relative;
    height: 100%;
    overflow-y: auto;
    padding-left: 0.5rem;
  }

  @include bp($mobile-breakpoint) {
    .c-popup--contact & {
      max-height: 600px;
      padding-bottom: 0;
    }
  }
}

.c-popup__body--self-contained {
  display: none;

  &.is-active {
    display: none;
  }

  .c-popup--self-contained-clone & {
    display: block;
  }

  .c-popup--self-contained-clone.is-active & {
    transform: translateY(0);
  }

  @include bp($mobile-breakpoint) {
    &.is-active {
      display: block;
    }
  }
}
