/* ==========================================================================
   WEIGHT
   ========================================================================== */
$weights: regular 400, medium 500, semibold 600, bold 700;

@each $weightName, $weightValue in $weights {
  .u-weight--#{$weightName} {
    font-weight: $weightValue;
  }
}
