/* ==========================================================================
   CTA
   ========================================================================== */

.c-cta {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.c-cta--job {
  display: block;
}

.c-cta__content {
  @include fp(margin-top, 16px, 32px);
  @include fp(font-size, 20px, 28px);

  text-align: center;
  font-weight: 600;
  color: $color-dark-blue;
  color: var(--font-color);

  @include bp($mobile-breakpoint) {
    max-width: percentage(10/12)
  }
}

.c-cta__content--job {
  @include fp(margin-top, 12px, 24px);
  text-align: left;
}

.c-cta__link {
  @include fp(margin-top, 40px, 80px);
}

.c-cta__pills {
  @include fp(margin-top, 24px, 40px);

  @include bp($mobile-breakpoint) {
    display: flex;
  }

  .c-cta--job & {
    flex-wrap: wrap;
    gap: 1rem 1.5rem;
  }
}
.c-cta__pill {
  & + & {
    margin-top: 1rem;

    @include bp($mobile-breakpoint) {
      margin-top: revert;
    }
  }

  .c-cta--job & {
    align-items: center;
    border: revert;
    display: flex;
    padding: revert;

    svg {
      width: 2rem;
      height: 2rem;
      margin-right: 0.5em;
    }
  }
}

.c-cta__card {
  @include fp(margin-top, 56px, 104px);
  @include fp(padding, 16px, 32px);
}
