/* ==========================================================================
   FILTER
   ========================================================================== */

.c-filter {
  position: relative;
  background-color: $color-white;
  border: 1px solid $color-steel;
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem $color-steel;
  margin-top: 2rem;
  z-index: 1;

  display: none;

  &::before,
  &::after {
    @include ps();
  }

  &::before {
    display: block;
    z-index: -2;
    top: -0.85rem;
    left: 1rem;
    width: 2rem;
    height: 2rem;
    background: transparent no-repeat 50% 50% / cover url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' id='icon-filter-arrow' viewBox='0 0 104 44'%3E%3Cpath d='M50.062 1.64a3 3 0 013.876 0l43.81 37.07A3 3 0 0195.81 44H8.19a3 3 0 01-1.938-5.29z' fill='%23fff' stroke-width='1' stroke='rgba(0, 45, 83, 0.15)' /%3E%3C/svg%3E");
  }

  &::after {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $color-white;
    border-radius: 0.5rem;
    z-index: -1;
  }

  &.is-active {
    display: block;
  }

  @include bp($mobile-breakpoint) {
    margin-top: 3.5rem;

    &::before {
      width: 4rem;
      height: 4rem;
      left: initial;
      top: -1.75rem;
      right: 1rem;
    }
  }
}

.c-filter__group {
  padding: 1.5rem;

  &:nth-child(even) {
    background-color: $color-grey;
  }

  &:last-child {
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  @include bp($mobile-breakpoint) {
    padding: 0.5rem 2rem;
    display: flex;
    align-items: center;
  }
}

.c-filter__label {
  text-transform: uppercase;
  font: 600 1rem $font-primary;
  color: $color-dark-blue;
  letter-spacing: 2px;
  white-space: nowrap;
  hyphens: none;

  @include bp($mobile-breakpoint) {
    flex: 1 0 10rem;
  }
}


.c-filter__choices {
  display: flex;
  flex-flow: row wrap;
  margin-top: 2rem;

  @include bp($mobile-breakpoint) {
    width: calc(100% - 10rem);
    margin-top: 0;
  }
}

.c-filter__item {
  display: flex;
  align-items: center;
  margin: 1rem 1rem 1rem 0;
  background-color: $color-white;

  span,
  svg {
    pointer-events: none; // prevent js from selecting the childs
  }

  svg {
    @include anim();
    stroke: $color-dark-blue;
    width: 0.8125rem;
    height: 0.5rem;
    margin-left: -1rem;
    opacity: 0;
  }

  &.is-selected {
    background-color: $color-steel;

    svg {
      margin-left: 0;
      margin-right: 0.5rem;
      opacity: 1;
    }
  }

  &.is-disabled {
    background-color: transparent;
    cursor: not-allowed;
    color: rgba($color-dark-blue, 0.5);
  }
}

.c-filter__item--focus-market {
  &.is-selected {
    background-color: var(--market-color);
  }
}
