///* ========================================================================
//   #MIXINS
//   ======================================================================== */

@mixin fp($properties, $min-value, $max-value, $min-vw: 320px, $max-vw: 1366px,) {
  @each $property in $properties {
    #{$property}: $min-value;
  }

  @media screen and (min-width: $min-vw) {
    @each $property in $properties {
      #{$property}: calc(#{$min-value} + #{strip-units($max-value - $min-value)} * (100vw - #{$min-vw}) / #{strip-units($max-vw - $min-vw)});
    }
  }

  @media screen and (min-width: $max-vw) {
    @each $property in $properties {
      #{$property}: $max-value;
    }
  }
}

@mixin anim($prop: all, $dur: 0.3s, $mode: $global-timing-func) {
  transition: $prop $dur $mode;
}

@mixin ps($content: '', $pos: absolute) {
  content: $content;
  position: $pos;
}

$spaceamounts: (2, 5, 8, 10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100);
$sides: (all, x, y, top, bottom, left, right);

@mixin make-space($prefix, $property) {
  $property-prefix: #{str-slice($property, 0, 1)};

  @each $space in $spaceamounts {
    @each $side in $sides {

      @if $side == 'all' {
        .#{$prefix}-#{$property-prefix}-#{$space} {
          #{$property}: #{$space}px !important;
        }
      } @else if $side == 'x' or $side == 'y' {
        .#{$prefix}-#{$property-prefix}#{$side}-#{$space} {

          @if $side == 'x' {
            #{$property}-left: #{$space}px !important;
            #{$property}-right: #{$space}px !important;
          } @else {
            #{$property}-top: #{$space}px !important;
            #{$property}-bottom: #{$space}px !important;
          }
        }
      } @else {
        .#{$prefix}-#{$property-prefix}#{str-slice($side, 0, 1)}-#{$space} {
          #{$property}-#{$side}: #{$space}px !important;
        }
      }
    }
  }
}

@mixin bgDimensions($width, $height) {
  padding-top: percentage($width / $height);
}

/**
 * Adobe XDs Letter spacing works with so-called milli-EM
 * @link https://community.adobe.com/t5/adobe-xd/units-for-character-spacing/m-p/8835010#M1064
 */

@mixin letterSpacing($val) {
  letter-spacing: calc(#{$val}em / 1000)
}
