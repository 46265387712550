/* ==========================================================================
   CHAPTER
   ========================================================================== */

$headlinePos: left flex-start, center center, right flex-end;

.c-chapter {
  position: relative;
  display: flex;
  color: $color-white;

  .c-widget--story-intro + .c-widget--story-chapter & {
    justify-content: center;
  }
}

.c-chapter--intro {
  justify-content: center;
}

@each $className, $justifyContent in $headlinePos {
  .c-chapter--align-#{$className} {
    justify-content: $justifyContent;
  }
}

.c-chapter--icon-align-right {
  text-align: right;
}

.c-chapter__icon {
  @include fp(width, 64px, 96px);
  @include fp(height, 64px, 96px);

  fill: currentColor;
  margin-right: 1rem;
  order: -1;

  .c-chapter--icon-align-right & {
    margin-right: 0;
    margin-left: 1rem;
    order: 0;
  }
}

.c-chapter__content {
  display: flex;
  flex-direction: column;

  @include bp-down($mobile-breakpoint) {
      word-break: break-word;
  }
}

.c-chapter__label {
  display: inline-block;

  &.c-text {
    color: $color-white;
  }
}

.c-chapter__headline {
  @include fp(font-size, 32px, 40px);

  display: inline-block;
  margin: 0;
  line-height: 1;

  h1, h2, h3 {
    font-size: inherit;
  }
}
