/* ==========================================================================
   #MAIN NAV
   ========================================================================== */

.c-nav {
  display: flex;
}

.c-nav--main {
  position: relative;
  display: none;

  &.menu-open {
    display: block;
  }

  @include bp($mobile-breakpoint) {
    position: static;
    display: block;
    height: 100%;
  }
}

.c-nav__subitem-mobile-only {
  display: none;
  @include bp-down($mobile-breakpoint) {
    display: block;
  }
}

/* Headline
   ========================================================================== */
.c-nav__headline {
  .dropdown-open & {
    transform: translateX(-100vw);
  }

  @include bp($mobile-breakpoint) {
    display: none;
  }
}

.c-nav__headline--dropdown {
  background: transparent;
  padding: 0;
  border: none;
  display: flex;
  align-items: flex-start;

  p {
    margin-bottom: 0;
  }

  svg {
    fill: currentColor;
    width: 1.25rem;
    height: 1.25rem;
    order: -1;
    margin-right: 0.5rem;
    transform: translateY(-0.375em);
  }

  .dropdown-open & {
    transform: translateX(0);
  }

  @include bp($mobile-breakpoint) {
    pointer-events: none;
    margin-left: 1rem;

    svg {
      display: none;
    }
  }
}

/* List
   ========================================================================== */
.c-nav__list {
  margin: 0;
}

.c-nav__list--main {
  @include bp($mobile-breakpoint) {
    display: flex;
    align-items: center;
    height: 100%;
  }
}

.c-nav__list--lang-switch,
.c-nav__list--popup {
  width: 100%;

  @include bp($mobile-breakpoint) {
    flex-flow: column wrap;
  }
}

.c-nav__list--horizontal {
  display: flex;
  align-items: center;
}

/* Items
   ========================================================================== */
.c-nav__item {
  list-style: none;
  padding: 0;
  margin: 0;

  .c-nav__list--horizontal & {
    margin-right: 0.5rem;

    &:last-child {
      margin-right: 0;
    }
  }

  @include bp($mobile-breakpoint) {
    &:hover,
    &.current-menu-item {
    }
  }
}

.c-nav__item--main {
  @include fp(font-size, 20px, 14px);

  display: contents;
  font-weight: 500;
  @include bp($mobile-breakpoint) {
    height: 100%;
    display: flex;
    align-items: center;
    font-weight: bold;
    margin-right: 1rem;
    align-self: center;

    &:last-child {
      margin-right: 0;
    }
  }
  @include bp(xlarge) {
    margin-right: 1.5rem;
  }
  @include bp(wide) {
    margin-right: 2rem;
  }
}

.c-nav--footer .c-nav__list {
  column-gap: 0.625rem;
}

.c-footer__widget__headline {
  margin-bottom: 0.5rem;
}

.c-nav__item--footer {
  @include fp(font-size, 14px, 12px);

  font-weight: normal;
  display: flex;
  align-items: center;

  &.c-nav__item--has-icon {
    svg {
      @include fp(width, 24px, 16px);
      @include fp(height, 24px, 16px);

      fill: currentColor;
      margin-right: 0.5rem;
    }
  }
}

.c-nav__item--legal {
  .c-nav__list--horizontal & {
    margin-right: 1rem;
  }
}

.c-nav__item--lang-switch,
.c-nav__item--popup {
  width: 100%;
  display: block;
  background-color: $color-grey;
  border: 1px solid $color-steel;
  border-radius: 0.25rem;
  margin: 0 0 0.5rem;
}

.c-nav__item-group {
  display: flex;

  button {
    position: absolute;
    width: 100%;
    text-align: right;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .dropdown-open & {
    button {
      transform: translateX(-100vw);
    }
  }

  @include bp($mobile-breakpoint) {
    button {
      display: none;
    }
  }
}

.c-nav__item--filterable {
  &.is-hidden {
    display: none;
  }
}

.c-nav__subitem {
  list-style: none;
  margin: 0;

  @include bp($mobile-breakpoint) {
    font-size: 1.25rem;
    font-weight: 500;
  }
}

/* Links
   ========================================================================== */
.c-nav__link {
  display: block;
  text-decoration: none;
}

.c-nav__link--main {
  margin: 0 0 1.25rem;

  .c-nav__item:last-child & {
    margin-bottom: 0;
  }

  &.current-menu-item {
    color: $color-hover;
  }

  .menu-item-has-children & {
    max-width: calc(100% - 1.25rem);
  }

  .dropdown-open & {
    transform: translateX(-100vw);
  }

  @include bp($mobile-breakpoint) {
    margin: 0;
    padding: 0.5rem 0.375rem;
    font-size: 0.875rem;
    border-radius: $global-radius;
    border: 1px solid transparent;

    .menu-item-has-children & {
      max-width: initial;
    }

    .c-nav__item:hover &,
    .current-menu-item & {
      background-color: $color-steel;
      border-color: $color-steel;
      color: $color-dark-blue;
    }
  }

  @media (min-width: 1100px) {
    font-size: 0.9375rem;
    padding: 0.5rem 0.5rem;
  }

  @include bp(xlarge) {
    font-size: 1rem;
    padding: 0.5rem 1rem;
  }
}

.c-nav__link--footer {
  margin-bottom: 0;
}

.c-nav__link--lang-switch,
.c-nav__link--popup {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1.5rem;
  margin-bottom: 0;
  border-radius: 0;

  svg {
    width: 1rem;
    height: 1rem;
    fill: $color-dark-blue;
    margin-left: auto;
  }

  &.c-nav__link--active {
    background-color: $color-steel;
    border: 1px solid $color-steel;
  }
}

.c-nav__link--popup {
  svg {
    &:first-child {
      width: 2rem;
      height: 2rem;
      margin-right: 1rem;
      margin-left: initial;
    }
  }
}

.c-nav__link--is-icon {
  padding: 0.375rem;

  svg {
    width: 100%;
    height: 100%;
  }

  @include bp($mobile-breakpoint) {
    width: 2rem;
    height: 2rem;
  }
}

.c-nav__sublink {
  @include anim($dur: 0.15s);
  display: block;
  text-decoration: none;
  padding: 0 0 1.25rem 1.75rem;

  svg {
    display: none;
    fill: currentColor;
  }

  @include bp($mobile-breakpoint) {
    padding: 0.5rem 1rem;
    border-radius: $global-radius;
    display: inline-flex;
    align-items: center;
    @include anim();

    svg {
      @include anim($dur: 0.15s);
      margin-left: 0.5rem;
      opacity: 0;
      transform: translateX(-0.25rem);
      display: block;
      width: 1rem;
      height: 1rem;
    }

    &:hover,
    .current-menu-item:not(.c-nav__subitem-anchor-link) > & {
      background-color: $color-dark-blue;
      color: $color-white;
    }

    &:hover {
      svg {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
}

/* Dropdown
   ========================================================================== */
.c-nav__dropdown-wrap {
  position: absolute;
  overflow: hidden;
  height: 0;
  top: 0;
  transform: translateX(100vw);

  &.dropdown-open {
    height: initial;
    transform: translateX(0);
  }

  @include bp($mobile-breakpoint) {
    @include anim();

    height: initial;
    overflow: initial;
    transform: none;
    top: calc(#{$global-logo-height} + 5rem);
    opacity: 0;
    visibility: hidden;

    &:before {
      @include ps();

      width: 150%;
      left: 50%;
      top: -5rem;
      bottom: -2rem;
      transform: translateX(-50%);
      z-index: -1;
    }

    .c-nav__item:hover > & {
      opacity: 1;
      visibility: visible;
    }
  }
}

.c-nav__dropdown {
  margin: 0;

  @include bp($mobile-breakpoint) {
    display: flex;
    flex-flow: column wrap;
    row-gap: 0.5rem;
  }
}

.c-nav__dropdown-wrap,
.c-nav__link,
.c-nav__link + button,
.c-nav__headline {
  @include anim();
}

.c-nav__link-icon {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: 1px solid $color-steel;
  margin-right: 1rem;
  object-fit: cover;
}
