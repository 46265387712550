/* ==========================================================================
   VIDEO
   ========================================================================== */

.c-video {
  position: relative;
}

.c-video--story {

  .plyr {
    background: transparent;
    border-radius: 0.5rem;
    border: 1px solid $color-steel;
  }
}

.c-video__controls {
  position: relative;
  display: flex;
  flex-flow: column wrap;
  height: 100%;

  &::after {
    @include ps();
    @include anim($dur: 0.5s);

    transition-delay: 0.15s;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-image: linear-gradient(to top, $color-dark-blue, transparent);
  }

  .c-video & {
    background: transparent;
    @include fp(padding-left, 12px, 140px);
    @include fp(padding-right, 12px, 140px);
    @include fp(padding-bottom, 18px, 40px);
  }

  .c-video--in-grid & {
    @include fp(padding-left, 12px, 32px);
    @include fp(padding-right, 12px, 32px);
  }

  .plyr--hide-controls & {
    &::after {
      transform: translateY(100%);
    }
  }

  @include bp($mobile-breakpoint) {
    &::after {
      height: 35%;
    }
  }

  .c-slider--video & {
    &::after {
      display: none;
    }
  }
}

.c-video__controls--story {
  justify-content: flex-start;

  &::after {
    top: 0;
    bottom: initial;
    background-image: linear-gradient(to bottom, rgba($color-dark-blue, 0.5), transparent);
  }

  .c-video & {
    background: transparent;
    padding: 1rem;
  }

  .plyr--video.plyr--hide-controls & {
    transform: translateY(-100%);

    &::after {
      transform: translateY(-100%);
    }
  }
}

.c-video__controls-meta {
  position: relative;
  z-index: 1;
  width: 100%;
  display: flex;
  flex-flow: row wrap;

  @include bp-down($mobile-breakpoint) {
    height: 100%;
  }
}

.c-video__controls-inner {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  align-self: flex-end;
}

.c-video__progress {
  width: 100%;

  input {
    &::-webkit-slider-thumb {
      appearance: none;
    }
  }
}

.c-video__time {
  position: relative;
  z-index: 1;
  font: 500 14px/18px $font-primary;
}

.c-video__time--duration {
  margin-left: auto;

  &:before {
    display: none;
  }
}

.c-video__control {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  svg {
    width: 100%;
    height: 100%;
    filter: drop-shadow(0 3px 1.5rem $color-dark-blue);
  }

  .c-video & {
    @include fp(width, 40px, 72px);
    @include fp(height, 40px, 72px);

    z-index: 2;
    padding: 0;

    &.plyr__tab-focus,
    &:hover,
    &[aria-expanded="true"] {
      background: transparent;
    }
  }

  .c-video--in-grid & {
    @include fp(width, 24px, 56px);
    @include fp(height, 24px, 56px);
  }
}

.c-video__data {
  align-self: flex-start;
  width: 100%;
  order: -1;
  text-align: left;
  margin-bottom: 1rem;
  display: flex;
  flex-flow: column wrap;

  @include bp($mobile-breakpoint) {
    margin-bottom: initial;
    margin-top: 1.25rem;
    max-width: initial;
    order: initial;
  }
}

.c-video__data--story {
  margin-top: 0;
}

.c-video__title {
  @include fp(font-size, 12px, 20px);
  @include fp(line-height, 16px, 22px);

  font-weight: bold;

  .c-video--in-grid & {
    @include fp(font-size, 12px, 16px);
    @include fp(line-height, 16px, 18px);
  }
}

.c-video__subtitle {
  @include fp(font-size, 12px, 14px);
  @include fp(line-height, 16px, 12px);

  font-weight: 500;
}

.plyr--paused .plyr__poster {
  opacity: 1;
}

.plyr__poster {
  background-size: cover;
}

.c-video__caption {
  font-weight: 700;
  font-size: 0.875rem;
}

.c-video__description {
  font-size: 0.75rem;
  max-width: 30ch;
}
