/* ==========================================================================
   CONTROLS
   ========================================================================== */

.c-controls {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  padding: 0 1rem;

  .c-accordion & {
    align-items: center;
    padding: 0;
  }
}

.c-controls--standalone {
  padding: 0;
}

.c-controls__headline {
  @include fp(font-size, 24px, 40px);
  @include fp(line-height, 24px, 40px);

  color: $color-dark-blue;
  margin: 0 0 0 1rem;
}

.c-controls__headline--accordion {
  @include fp(font-size, 18px, 28px);
  @include fp(line-height, 18px, 28px);

  font-family: $font-primary;
  font-weight: 600;
}

.c-controls__back {
  height: 2.25rem;
  width: 2.25rem;
  padding: 0;
  background-color: $color-grey;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    position: relative;
    display: block;
    line-height: 1;
    font-weight: 500;
    font-size: 1.5rem;
    transform: translateY(-0.075em);
  }

  &:hover {
    border-color: $color-steel;
    color: $color-dark-blue;
  }
}

.c-controls__col {
  display: flex;
  align-items: center;
  width: 100%;

  @include bp($mobile-breakpoint) {
    width: initial;
  }
}

.c-controls__col--right {
  margin-top: 1rem;
  flex-flow: row-reverse wrap;
  align-items: flex-start;
  justify-content: space-between;
  z-index: 1;

  @include bp($mobile-breakpoint) {
    margin-top: 0;
    margin-left: auto;
    flex-flow: row wrap;
    justify-content: initial;
  }

  @include bp-down($mobile-breakpoint) {
    .c-accordion__controls & {
      margin-top: 0;

      .is-active & {
        margin-top: 1rem;
      }
    }
  }
}

.c-controls__toggle,
.c-controls__btn-group {

  @include bp-down($mobile-breakpoint) {
    .c-accordion__controls & {
      display: none;
    }

    .is-active & {
      display: flex;
    }
  }

  @include bp($mobile-breakpoint) {
    .c-accordion__controls & {
      @include anim();

      z-index: 1;
      opacity: 0;
      visibility: hidden;
    }

    .is-active & {
      opacity: 1;
      visibility: visible;
    }
  }
}

.c-controls__toggle {
  display: flex;

  @include bp-down($mobile-breakpoint) {
    margin-left: 0;
  }
}

.c-controls__btn-group {
  display: flex;
  flex-flow: column wrap;

  @include bp($mobile-breakpoint) {
    flex-flow: row nowrap;
  }
}

.c-controls__btn {
  margin-bottom: 1rem;
  font-weight: 500;
  border: 1px solid rgba(0, 45, 83, 0.15);

  &.is-disabled,
  &.is-loading {
    pointer-events: none;
  }

  &.is-disabled {
    background-color: $color-grey;
  }

  &.is-loading {
    background-color: rgba($color-dark-blue, 0.5);
  }

  @include bp-down($mobile-breakpoint) {
    &:last-child {
      margin-bottom: 0;
    }
  }

  @include bp($mobile-breakpoint) {
    margin-left: 1rem;
    margin-bottom: 0;

    &:first-child {
      margin-left: 4rem;
    }
  }

  .c-accordion__controls & {
    @include bp($mobile-breakpoint) {
      &:first-child {
        margin-left: 1rem;
      }
    }
  }
}

.c-controls__btn {
  display: flex;
  align-items: center;
}

.c-controls__btn--dl {
  > span {
    flex: 1 0 auto;
  }
  svg {
    @include anim();

    flex: 1 0 1rem;
    opacity: 0;
    margin-right: -1.5rem;
    margin-left: 0.5rem;
    width: 1rem;
    height: 1rem;
  }

  &.is-loading {
    padding-right: 2.5rem;

    svg {
      margin-right: 0;
      opacity: 1;
    }
  }
}

.c-controls__icon {
  width: 2.5rem;
  height: 2.5rem;
}
