.c-fact--grid.c-fact--style-checklist {
  display: block;
  max-width: 768px;
  padding: 0;
  @include bp(small) {
    padding: 1.5rem;
  }
  &:not(.c-fact--has-border) {
    border: none;
  }
  .header {
    margin-bottom: 2rem;
  }

  h3 {
    font-size: 1rem;
    font-family: $font-primary, sans-serif;
    font-weight: 500;
    margin-bottom: 1rem;
  }
  h4 {
    font-size: 1.5rem;
    line-height: 1.3;
    margin: 0;
    span {
      display: block;
    }
  }

  li .content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 1rem;
    font-size: 1.25rem;
    font-weight: 600;
    @include bp(small) {
      font-size: 1.5rem;
    }
    svg {
      width: 1.8125rem;
      height: 1.8125rem;
      flex: 0 0 1.8125rem;
    }
  }
  li .arrow {
    text-align: center;
    padding: 1rem 0;
    svg {
      display: inline-block;
      height: 64px;
    }
  }

  li:last-child .arrow {
    display: none;
  }

  .text {
    margin-top: 2rem;
    font-size: 1rem;
    font-weight: 600;
  }
}
