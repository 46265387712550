.c-widget--text.c-widget--has-infobox {
  .o-container {
    @include bp($mobile-breakpoint) {
      display: flex;
      column-gap: 4rem;
      > * {
        flex: 50%;
      }
    }
  }
}

.c-widget__infobox {
  margin-top: 3rem;
  @include bp($mobile-breakpoint) {
    margin-top: 0;
  }
  h3 {
    font-family: 'Montserrat', sans-serif;
    font-size: 0.875rem;
    color: var(--up-purple);
    font-weight: 500;
    margin-bottom: 0.75rem;
  }
  nav {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    border: 1px solid var(--up-purple);
    padding: 1.25rem;
    border-radius: 6px;
    font-family: $font-headings, sans-serif;
    @include bp(xlarge) {
      padding: 2rem;
    }
  }
  a {
    font-size: 1rem;
    @include bp(small) {
      font-size: 1.125rem;
    }
    @include bp(xlarge) {
      font-size: 1.5rem;
    }
    transition: color 0.25s ease-in-out;
    font-weight: bold;
    text-decoration: none;
    text-transform: uppercase;
    svg {
      transition: fill 0.25s ease-in-out;
      width: 1em;
      height: 1em;
      transform: rotate(-45deg);
      fill: #fff;
    }
  }
  a:not(.c-btn):hover {
    color: var(--up-purple);
    svg {
      fill: var(--up-purple);
    }
  }
}
