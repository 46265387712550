/* ==========================================================================
   SINGLE FILE /DOWNLOADS
   ========================================================================== */

.c-file {
  display: flex;
  align-items: center;
  border-top: 1px solid $color-steel;

  &:last-child {
    border-bottom: 1px solid $color-steel;
  }

  .c-files__list--grid & {
    border: none;
  }
}

.c-file__label {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 0.5rem;

  .c-file__input:checked + & {
    background-color: rgba($color-light-blue, 0.25);
  }

  .c-files__list--grid & {
    padding: 1rem;
    flex-wrap: wrap;
    align-content: flex-start;
  }

  @include bp($mobile-breakpoint) {
    padding: 1rem;

    .c-files__list--grid & {
      align-items: initial;
      height: 100%;
    }
  }
}

.c-file__input {
  display: none;
}

.c-file__col {
  padding: 0 2rem;
  font-size: 0.75rem;
  flex: 1;
  color: $color-dark-blue;

  .c-files__list--grid & {
    &.hide-in-grid {
      display: none;
    }
    flex: initial;
    padding: 0;

    &:nth-child(3):not(.c-file__col--checkbox) {
      padding-left: 2.5rem;

      &:after {
        content: '-';
        padding: 0 0.25rem;
      }
    }
  }
}

.c-file__col--checkbox {
  flex: 0 0 2rem;
  position: relative;
  appearance: none;
  margin: 0 0 0 auto;
  padding: 0;
  transform: translateX(-0.25rem);
  border-radius: 50%;
  display: block;
  width: 2rem;
  height: 2rem;
  background-color: #fff;
  border: 1px solid $color-steel;

  &::after {
    @include anim($dur: 0.15s);
    @include ps();

    width: 1.5rem;
    height: 1.5rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    border-radius: 50%;
    background-color: $color-dark-blue;
  }

  .c-file__input:checked + .c-file__label & {
    &::after {
      transform: translate(-50%, -50%) scale(1)
    }
  }

  .c-files__list--grid & {
    position: absolute;
    top: 2rem;
    left: 2rem;
  }

  @include bp-down($mobile-breakpoint) {
    margin-left: auto;
  }

  @include bp($mobile-breakpoint) {
    .c-files__list--grid & {
      position: relative;
      top: initial;
      left: initial;
      margin-left: initial;
      order: -1;
      margin-top: 0.5rem;
    }
  }
}

.c-file__thumb {
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  flex: 0 0 3rem;

  svg {
    width: 2rem;
    height: 2.5rem;
  }

  .c-files__list--grid & {
    &,
    &.c-img {
      position: relative;
      height: initial;

      &::before {
        content: '';
        width: 100%;
        padding-bottom: 100%;
      }

      flex: 0 0 100%;
      width: 100%;
    }

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 50%;
      height: auto;
    }
  }

  @include bp($mobile-breakpoint) {
    .c-files__list--grid & {
      order: -1;
      align-self: flex-start;

      &.c-img {
        height: initial;

        &::before {
          width: 100%;
        }
      }
    }
  }
}

.c-file__thumb--img {
  border: 1px solid $color-steel;

  &.c-img {
    width: 3rem;
  }
}

.c-file__col--span {
  padding: 0;

  @include bp($mobile-breakpoint) {
    width: 60%;
    flex: 0 0 60%;
    padding: 0 1rem;

    .c-files__list--grid & {
      width: calc(100% - 2rem);
      flex-basis: initial;
      padding: 0;
      padding-left: 0.5rem;
    }
  }
}

.c-file__col--name {
  display: flex;

  .c-files__list--grid & {
    flex-flow: row wrap;
  }

  .c-file--search & {
    width: calc(100% - 3rem - 1rem);
  }

  @include bp($mobile-breakpoint) {
    align-items: center;

    .c-files__list--grid & {
      align-self: flex-start;
    }
  }
}

.c-file__title {
  padding-left: 1rem;
  font-weight: 500;

  span {
    font-weight: 400;
    padding: 0;
  }

  .c-files__list--grid & {
    padding-left: 0;
    padding-top: 1rem;
  }

  .c-file--search & {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 95%;
  }

  @include bp($mobile-breakpoint) {
    padding-left: 0;

    span {
      font-weight: 400;
    }

    .c-files__list--grid & {
      padding-top: 0.5rem;
    }
  }
}

.c-file__ghost-el {
  display: none;

  .c-files__list--grid & {
    display: block;
  }
}
