/* ==========================================================================
   CARD
   ========================================================================== */

.c-card {
  box-shadow: 0 1.5rem 1.5rem $color-steel;
  position: relative;
  background-color: $color-white;
  border: 1px solid $color-steel;
  border-radius: 0.25rem;

  @include bp($mobile-breakpoint) {
    display: flex;
    align-items: center;
    margin: {
      left: -2rem;
      right: -2rem;
    }
  }
}

.c-card__img {
  @include fp(height, 56px, 88px);

  background: $color-steel no-repeat 50% 50% / cover;
  border-radius: 50%;
  box-shadow: 0 0.5rem 1rem rgba($color-steel, 0.15);

  &.c-img {
    @include fp(width, 56px, 88px);
  }
}

.c-card__content {
  margin-top: 1.5rem;

  span {
    display: block;
    &:first-child {
      font-weight: 700;
      font-size: 0.875rem;
    }
    &:last-child {
      font-size: 0.75rem;
    }
  }

  @include bp($mobile-breakpoint) {
    margin-top: 0;
    margin-left: 2rem;
  }
}

.c-card__btn {
  margin-top: 2rem;
  font-weight: 700;

  @include bp($mobile-breakpoint) {
    margin-top: 0;
    margin-left: auto;
  }
}
