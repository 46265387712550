/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
  @include fp(padding-top, 32px, 64px);
  @include fp(padding-bottom, 16px, 32px);
  @include fp(font-size, 14px, 12px);
  @include fp(line-height, 24px, 22px);

  position: relative;
  padding-left: 1rem;
  padding-right: 1rem;
  color: $color-dark-blue;
  background-color: $color-grey;
  z-index: 1;

  @include bp($mobile-breakpoint) {
    margin-top: auto;
  }
}

.c-footer__cols {
  flex-wrap: wrap;
  &.is-mobile {
    display: flex;
  }

  &.is-reverse {
    flex-direction: column-reverse;
  }

  @include bp-down($mobile-breakpoint) {
    padding-left: 0;
    padding-right: 0;

    &.is-reverse-mobile {
      flex-direction: column-reverse;
    }
  }

  @include bp($mobile-breakpoint) {
    display: flex;

    &.is-reverse {
      flex-direction: row-reverse;
    }
  }
}

.c-footer__col {
  align-self: flex-start;
  display: flex;
  margin-bottom: 2.25rem;

  &:first-child,
  .c-footer__col &,
  .c-footer__cols:last-child & {
    margin-bottom: 0;
  }

  @include bp-down($mobile-breakpoint) {
    .is-reverse-mobile & {
      &:first-child {
        border-top: 1px solid $color-steel;
        margin-top: 2.5rem;
        padding-top: 1.5rem;
      }
    }
  }

  @include bp($mobile-breakpoint) {
    margin-bottom: 0;

    flex: 1 0 auto;
  }
}

.c-footer__col--full-width {
  width: 100%;

  @include bp($mobile-breakpoint) {
    width: initial;
  }
}

.c-footer__col--column-desktop {
  @include bp($mobile-breakpoint) {
    flex-flow: column wrap;
  }
}

.c-footer__col--copyright {
  @include bp($mobile-breakpoint) {
    justify-content: flex-end;
  }
}

.c-footer__widget {
  p {
    margin: 0;
  }

  &:last-child:not(:only-child) {
    @include bp($mobile-breakpoint) {
      margin-top: 1rem;
    }
    @include bp-down($mobile-breakpoint) {
      margin-left: auto;
    }
  }

  @include bp($mobile-breakpoint) {f
    .c-footer__col--column-desktop & {
      &:last-child:not(:only-child) {
        margin-left: initial;
        margin-top: 1rem;
      }
    }
  }

}

.c-footer__widget__headline {
  font-size: inherit;
  font-family: inherit;
  text-transform: initial;
  margin: 0;
}

.c-footer__separator {
  margin: 2rem 0;
}

.c-footer__separator--large {
  @include fp(margin-top, 72px, 94px);
  @include fp(margin-bottom, 32px, 24px);
}
