/* ==========================================================================
   #CONTAINER
   ========================================================================== */

/**
 * Container class for main content
 * 1. Get the distance of the first main-nav element
*/

.o-container {
  @include bp(large) {
    max-width: 90vw;
    margin: 0 auto;
  }

  @include bp(xlarge) {
    max-width: calc(100vw - (4rem + 3.5rem + 1rem) * 2); /* [1] */
    margin: 0 auto;
  }

  @include bp($max-width-breakpoint) {
    max-width: calc(100% - (4rem + 3.5rem + 1rem) * 2);
  }
}

.o-container--relative {
  position: relative;
}
