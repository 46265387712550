/* ==========================================================================
   STATEMENT
   ========================================================================== */

.c-statement-wrap {
  @include bp($mobile-breakpoint) {
    display: flex;
  }
}

.c-statement {
  position: relative;

  &::before,
  &::after {
    @include ps($pos:relative);
  }

  &::before {
    width: 1px;
    height: 0;
    margin-left: -1px;
    float: left;
    padding-bottom: percentage(540/450);
  }

  &::after {
    display: table;
    clear: both;
  }

  @include bp($mobile-breakpoint) {
    width: calc(#{percentage(1/3)} - 0.0833rem); // only three items get margin left 1rem
    margin-left: 0.25rem;

    &:first-child {
      margin-left: 0;
    }
  }
}

.c-statement__inner {
  @include bp($mobile-breakpoint) {
    height: 100%;
  }
}

.c-statement__content {
  @include fp(padding, 24px, 48px);

  position: relative;
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-end;
  height: 100%;
  color: $color-white;
  background: transparent no-repeat 50% 50% / cover;

  &::after {
    @include ps();
    bottom: 0;
    left: 0;
    width: 100%;
    height: 75%;
    z-index: 0;
    background-image: linear-gradient(to top, $color-dark-blue, transparent);
  }
}

.c-statement__quote {
  position: relative;
  z-index: 1;
  font: normal 600 1.25rem/1.75 $font-primary;
  margin: 0;
  quotes: "«" "»";

  p {
    margin: 0;
    quotes: '»' '«';
  }
}

.c-statement__cite {
  display: block;
  z-index: 1;
  margin-top: 1.5rem;
  font: normal 0.75rem $font-primary;
}
