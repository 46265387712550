#up-social-media-widget {
  position: fixed;
  bottom: 15vh;
  right: 0;
  z-index: 201;
  margin-top: -100px;
  @include bp-down($mobile-breakpoint) {
    bottom: 2svh;
  }
  .pill {
    position: relative;
    height: 45px;
    width: 54px;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
    background-color: $color-grey;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0.3125rem;
    transition: $global-transition;
    box-shadow: 0 0.25rem 0.25rem rgba(100, 100, 100, 0.125);
    &:hover {
      background-color: $color-up-pink;
    }
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    line-height: 1;
    transition: $global-transition;
    color: $color-dark-blue;
    &:hover {
      transform: scale(1.07);
    }
  }

  svg {
    width: 26px;
    height: 26px;
    fill: currentColor;
    vertical-align: middle;
  }

  .pill-social svg {
    width: 20px;
    height: 20px;
  }

  .tooltip {
    font-size: 10px;
    width: 100px;
    left: -100px;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 0.5rem;
    color: #fff;
    pointer-events: none;
    transition: $global-transition;
    position: absolute;
    opacity: 0;
    visibility: hidden;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  }

  .pill:hover .tooltip {
    visibility: visible;
    opacity: 0.8;
  }
}
