/* ==========================================================================
   ARROWS
   ========================================================================== */

.c-arrow {
  @include anim();

  display: inline-block;
  transform: translate(0);
}

.c-arrow--right {
  a:hover & {
    transform: translateX(0.25rem);
  }
}