/* ==========================================================================
   SCROLLY
   ========================================================================== */

.c-scrolly__content {
  position: relative;
  @include bp($mobile-breakpoint) {
    display: flex;
    align-items: flex-start;
    padding-top: 10rem;
  }
}

.c-scrolly__parents {
  @include bp($mobile-breakpoint) {
    width: percentage(5/12);
    position: sticky;
    top: calc(var(--app-height) / 2 - #{$global-logo-size} + 4rem);
    transform: translateY(-50%)
  }
}

.c-scrolly__childs {
  @include bp($mobile-breakpoint) {
    width: percentage(4/12);
    margin-left: percentage(3/12);
    display: flex;
    flex-direction: column;
  }
}

.c-scrolly__item {
  + .c-scrolly__item {
    margin-top: 4.5rem;
  }
}

.c-scrolly__parent {
  @include anim($prop: color);
  margin-top: 1rem;
  color: var(--color);
  display: flex;
  flex-flow: row wrap;
  background-color: transparent;
  border: none;
  text-align: left;

  &.is-button {
    cursor: pointer;
  }

  &.is-active,
  &.is-button:hover,
  .c-accordion__item.is-active & {

    svg {
      transform: translateX(1rem);
    }
  }

  + .c-scrolly__parent {
    margin-top: 3rem;
  }

  svg {
    @include anim($prop: transform);

    width: 2rem;
    height: 2rem;
    margin-top: 1rem;
    fill: currentColor;
  }

  .c-accordion__item.is-active & {
    svg {
      transform: rotate(180deg)
    }
  }

  @include bp($mobile-breakpoint) {
    @include fp(margin-top, 72px, 104px);

    flex-flow: row nowrap;
    align-items: center;

    svg {
      flex: 1 0 auto;
      margin-left: 2rem;
    }

    + .c-scrolly__parent {
      @include fp(margin-top, 104px, 148px)
    }
  }
}

.c-scrolly__label {
  &.c-text {
    color: var(--main-color);
  }
}

.c-scrolly__question {
  @include fp(font-size, 20px, 30px);

  font-family: $font-headings;
  text-transform: uppercase;
  line-height: 1;
}

.c-scrolly__step {
  position: relative;
  color: var(--color);
  fill: currentColor;
  font-size: 0.875rem;

  svg {
    width: 100%;
    height: 10rem;
    margin-bottom: 3rem;
  }

  margin-bottom: 5rem;

  @include bp-down($mobile-breakpoint) {
    &:last-child {
      margin-bottom: 0;
    }
  }

  @include bp($mobile-breakpoint) {
    margin-bottom: 5rem;
  }
}


.c-scrolly__gradient {
  @include anim();

  opacity: 0;
  visibility: hidden;

  &.is-active {
    opacity: 1;
    visibility: visible;
  }

  @include bp($mobile-breakpoint) {
    height: calc(var(--app-height) - #{$global-logo-size});
    top: $global-logo-size;
    z-index: 1;
    right: 0;
    position: fixed;
    width: percentage(4/12);
    margin-left: percentage(3/12);
    background-image: linear-gradient($color-dark-blue 5%, transparent 10%, transparent 90%, $color-dark-blue 95%);
  }
}
