/* ==========================================================================
   CHECKLIST
   ========================================================================== */

.c-checklist {
  max-width: 34rem;
  margin: 0 auto;
  &.c-checklist-horizontal {
    max-width: unset;
  }
}

.c-checklist__header {
  display: flex;

  &.has-arrow-bottom {
    flex-direction: column;
  }
}

.c-checklist__arrow {
  display: flex;
  justify-content: space-between;

  @include bp($mobile-breakpoint) {
    &.side {
      margin-left: auto;
    }
  }
}

.c-checklist__arrow-icon {
  stroke: $color-dark-blue;
  margin-right: 1rem;
  display: block;
  width: 3rem;
  height: 3rem;

  .c-widget--has-dark-bg & {
    stroke: $color-grey;
  }

  .side & {
    width: 6.5rem;
    height: 6.5rem;
  }

  &:last-child {
    margin: 0;
  }

  @include bp($mobile-breakpoint) {
    width: 5rem;
    height: 5rem;
  }
}

.c-checklist__title {
  line-height: 1.125;
}

.c-checklist__body {
  margin: 2rem 0 0;
  padding: 0;
  @include bp($mobile-breakpoint) {
    margin-top: 3rem;
  }
}

.c-checklist-horizontal .c-checklist__body {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 1.5rem;
  column-gap: 1.5rem;
}

.c-checklist__item {
  @include fp(padding-top, 12px, 20px);
  @include fp(padding-bottom, 12px, 20px);

  display: flex;
  align-items: center;
  padding-right: 2rem;
  @include bp-down($mobile-breakpoint) {
    padding-right: 1rem;
  }
  span {
    @include fp(font-size, 20px, 28px);

    font-weight: 600;
  }

  svg {
    width: 2rem;
    height: 2rem;
    flex: 0 0 2rem;
    margin-right: 1rem;
    fill: $color-steel;
    stroke: rgba(0, 45, 83, 0.25);
  }

  .c-widget--has-dark-bg & {
    svg {
      fill: rgba(242, 239, 239, 0.15);
      stroke: rgba(242, 239, 239, 0.25);
    }
  }
}

.c-checklist__text {
  margin-top: 2rem;
}

.c-checklist__btn {
  margin-top: 2rem;
}
