/* ==========================================================================
   SEARCH RESULT BLOCK
   ========================================================================== */

.c-results {
  @include fp(padding-top, 80px, 136px);
  @include fp(padding-bottom, 144px, 224px);

  padding: {
    left: 2rem;
    right: 2rem;
  }

  @include bp($mobile-breakpoint) {
    padding: {
      left: 0;
      right: 0;
    }
  }
}

.c-results__body {
  @include fp(margin-top, 32px, 64px);
}

.c-result {
  @include fp(padding-left, 16px, 32px);
  @include fp(padding-right, 16px, 32px);
  @include fp(padding-top, 24px, 40px);
  @include fp(padding-bottom, 24px, 40px);
  @include fp(margin-bottom, 16px, 32px);

  border: 1px solid $color-steel;
  border-radius: 0.25rem;

  &:last-child {
    margin-bottom: 0;
  }

  &:first-child {}
}

.c-result__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.c-result__header--nested {
  &:nth-child(1n+2) {
    @include fp(margin-top, 24px, 48px);
  }
}

.c-result__headline {
  @include fp(font-size, 20px, 28px);
  @include fp(line-height, 30px, 44px);

  margin: 0;
  color: $color-dark-blue;
  font-weight: 600;
  font-family: $font-primary;
  text-transform: none;

  .c-result__header--nested & {
    font-size: 0.75rem;
  }
}

.c-result__body {
  @include fp(margin-top, 24px, 48px);
}

.c-result__body--service,
.c-result__body--post {
  @include bp($mobile-breakpoint) {
    display: flex;
    flex-wrap: wrap;
  }
}

.c-result__body--focus-market {
  display: flex;
  flex-wrap: wrap;
}

.c-result__btn {
  @include fp(font-size, 12px, 16px);

  font-weight: 500;
}
