/* ==========================================================================
   SLIDER
   ========================================================================== */

.c-slider--story {
  padding-left: 2rem;
  overflow: initial;

  @include bp($mobile-breakpoint) {
    padding-left: 0;
  }
}

.c-slider--with-offset {
  &.swiper-container {
    overflow: visible;
  }
}

.c-slider__item {
  width: 260px;
}

.c-slider__caption {
  color: $color-dark-blue;
  margin-top: 0.5rem;
  line-height: 1;

  @include bp($mobile-breakpoint) {
    max-width: 50%;
  }
}

.c-slider__title {
  font-size: 0.875rem;
}

.c-slider__subtitle {
  margin-top: 0.5rem;
  font-size: 0.75rem;
}

.c-slider__pagination {
  @include fp(margin-top, 20px, 104px);

  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  bottom: 0;
}

.c-slider__pagination-bullet {
  @include anim($prop: background-color);

  flex: 1;
  height: 0.25rem;
  background-color: $color-steel;
  margin-right: 1rem;
  max-width: 168px;
  border-radius: 0.25rem;

  .c-slider__pagination--light & {
    background-color: rgba($color-white,0.15);
  }

  &:last-child {
    margin-right: 0;
  }
}

.c-slider__pagination-bullet--active {
  background-color: $color-dark-blue;

  .c-slider__pagination--light & {
    background-color: $color-white;
  }
}
