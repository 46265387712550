/* ==========================================================================
   #FONT-FACE
   ========================================================================== */

/**
 * Add font-display: swap to font-face blocks here
 * https://www.zachleat.com/web/comprehensive-webfonts/#font-display
 */

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-Bold.eot');
  src: url('../assets/fonts/Montserrat/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/Montserrat/Montserrat-Bold.woff2') format('woff2'),
  url('../assets/fonts/Montserrat/Montserrat-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-Italic.eot');
  src: url('../assets/fonts/Montserrat/Montserrat-Italic.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/Montserrat/Montserrat-Italic.woff2') format('woff2'),
  url('../assets/fonts/Montserrat/Montserrat-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-SemiBold.eot');
  src: url('../assets/fonts/Montserrat/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/Montserrat/Montserrat-SemiBold.woff2') format('woff2'),
  url('../assets/fonts/Montserrat/Montserrat-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-Regular.eot');
  src: url('../assets/fonts/Montserrat/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/Montserrat/Montserrat-Regular.woff2') format('woff2'),
  url('../assets/fonts/Montserrat/Montserrat-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-Medium.eot');
  src: url('../assets/fonts/Montserrat/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/Montserrat/Montserrat-Medium.woff2') format('woff2'),
  url('../assets/fonts/Montserrat/Montserrat-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}



@font-face {
  font-family: 'Syncopate';
  src: url('../assets/fonts/Syncopate/Syncopate-Bold.eot');
  src: url('../assets/fonts/Syncopate/Syncopate-Bold.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/Syncopate/Syncopate-Bold.woff2') format('woff2'),
  url('../assets/fonts/Syncopate/Syncopate-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

