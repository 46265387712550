/* ==========================================================================
   SOCIAL
   ========================================================================== */

$platform_bg: (
        instagram: linear-gradient(to right, #4e5dca 0%, #c837ab 32%, #ff583f 70%, #ffd854 100%),
        twitter: #1DA1F1,
        facebook: #1777F2,
        youtube: #FF0000,
        linkedin: #0866C2,
);

.c-social {
  position: relative;
  color: #fff;
}

@each $p, $pC in $platform_bg {
  .c-social--#{$p} {
    background: $pC;
  }
}

.c-social__content {
  @include fp(padding, 16px, 32px);

  a {
    color: inherit;

    &:hover {
      color: rgba($color-dark-blue, 0.9)
    }
  }
}

.c-social__handle {
  @include fp(padding-top, 16px, 32px);
  @include fp(padding-left, 16px, 32px);
  @include fp(padding-right, 16px, 32px);

  display: block;
  text-shadow: 0 0 0.25rem $color-dark-blue;
  font: 700 0.635rem $font-primary;
  text-transform: uppercase;

  .c-social--instagram &,
  .c-social--youtube & {
    @include fp(top, 16px, 32px);
    @include fp(left, 16px, 32px);

    padding: 0;
    position: absolute;
    z-index: 1;
  }
}

.c-social__content {
  @include fp(font-size, 18px, 20px);
  @include fp(line-height, 27px, 30px);

  font-weight: 600;

  img {
    margin-top: 1rem;
  }
}

.c-social__banner {
  @include fp(padding, 16px, 32px);

  color: $color-white;
  text-decoration: none;
  display: flex;
  align-items: center;

  &:hover {
    color: rgba($color-white, 0.75)
  }
}

.c-social__icon {
  width: 1.5rem;
  height: 1.5rem;
}

.c-social__posted-text {
  font: 700 0.875rem $font-primary;
  margin-left: 0.5rem;
}

.c-social__time {
  font: normal 0.875rem $font-primary;
  margin-left: auto;
}