/* ==========================================================================
   TOGGLE
   ========================================================================== */

.c-toggle {
  position: relative;
}

.c-toggle__input {
  position: relative;
  appearance: none;
  width: 5rem;
  border-radius: 1.5rem;
  height: 2.25rem;
  padding: 0;
  outline: none;
  border: 1px solid $color-steel;
  background-color: #fff;
  cursor: pointer;

  &::before,
  &::after {
    @include ps();

    z-index: 2;
    width: 50%;
    height: 100%;
    text-align: center;
    background: no-repeat transparent 50% 50% / 0.75rem 0.75rem;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
  }

  &::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 14'%3E%3Cpath fill='none' stroke='%23002d53' stroke-linejoin='round' stroke-width='2' d='M1 1h12v8H1z' data-name='Rectangle 1543'/%3E%3Cpath fill='none' stroke='%23002d53' stroke-linecap='round' stroke-width='2' d='M1 13h12' data-name='Line 88'/%3E%3C/svg%3E");
    left: 0;
  }

  &::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 14'%3E%3Cg data-name='Group 3412'%3E%3Cg data-name='Group 3415'%3E%3Cg data-name='Group 3413'%3E%3Cg fill='none' stroke='%23002d53' stroke-linecap='round' stroke-width='2' data-name='Group 3412'%3E%3Cpath d='M5 1h8' data-name='Line 85'/%3E%3Cpath d='M5 7h8' data-name='Line 86'/%3E%3Cpath d='M5 13h8' data-name='Line 87'/%3E%3C/g%3E%3C/g%3E%3Cg data-name='Group 3414'%3E%3Cg fill='none' stroke='%23002d53' stroke-linecap='round' stroke-width='2' data-name='Group 3412'%3E%3Cpath d='M1 1h0' data-name='Line 85'/%3E%3Cpath d='M1 7h0' data-name='Line 86'/%3E%3Cpath d='M1 13h0' data-name='Line 87'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    right: 0;
  }
}

.c-toggle__overlay {
  @include anim();
  position: absolute;
  top: 0;
  height: 2.25rem;
  border-radius: 0 1.5rem 1.5rem 0;
  z-index: 1;
  background-color: $color-grey;
  border: 1px solid $color-steel;

  .c-toggle__input:checked + & {
    left: 50%;
    right: 0;
    // transition: border-radius 0.15s $global-timing-func, right 0.15s $global-timing-func, left 0.15s 0.2s $global-timing-func;
  }

  .c-toggle__input:not(:checked) + & {
    left: 0;
    border-radius: 1.5rem 0 0 1.5rem;
    right: 50%;
    // transition: border-radius 0.15s $global-timing-func, left 0.15s $global-timing-func, right 0.15s 0.2s $global-timing-func;
  }
}
