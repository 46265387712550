/* ==========================================================================
   SHARE
   ========================================================================== */

.c-share {
  display: flex;
  margin-left: 1rem;
}

.c-share__btn {
  @include anim($dur: 0.15s);

  transition-property: transform, opacity, visiblity, background-color;
  transform: translateY(1rem);
  opacity: 0;
  visibility: hidden;
  width: 2.25rem;
  height: 2.25rem;
  padding: 0.5rem;
  margin-right: 0.5rem;
  fill: $color-dark-blue;

  &:last-child {
    margin-right: 0;
  }

  .is-active & {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }

  .has-dark-color & {
    background-color: $color-white;
    border: 1px solid transparent;

    svg {
      @include anim();
    }

    &:hover {
      background-color: transparent;
      fill: $color-white;
      border-color: $color-white;
    }
  }
}
