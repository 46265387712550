/* ==========================================================================
   ACCORDION
   ========================================================================== */

.c-accordion--table {
  @include fp(margin-top, 24px, 40px);
}

.c-accordion--content {
  width: 100%;
  padding: {
    left: 2rem;
    right: 2rem;
  };

  @include bp($mobile-breakpoint) {
    padding: {
      left: 0;
      right: 0;
    };
  }
}

.c-accordion__item--table {
  @include anim();

  position: relative;
  background-color: $color-grey;
  border-radius: 0.25rem;
  border: 1px solid $color-steel;
  padding: 1rem 1.5rem;
  z-index: 1;
  margin-bottom: 0.5rem;

  &.is-active {
    background: #fff;
  }

  @include bp($mobile-breakpoint) {
    padding: 2.5rem 2rem;
  }
}

.c-accordion__item--content {
  margin-top: 0.5rem;

  &:first-child {
    margin-top: 0;
  }
}

.c-accordion__item-head {
  .is-active & {
    position: relative;
  }
}

.c-accordion__trigger {
  cursor: pointer;
}

.c-accordion__trigger--content {
  @include fp(font-size, 20px, 28px);
  @include fp(padding-top, 16px, 40px);
  @include fp(padding-bottom, 16px, 40px);
  @include fp(padding-left, 16px, 24px);
  @include fp(padding-right, 16px, 24px);

  font-weight: 600;
  background-color: $color-grey;
  color: $color-dark-blue;
  width: 100%;
  display: flex;
  align-items: center;
  font-family: $font-primary;
  border: 1px solid $color-steel;
  border-radius: 0.25rem;
  text-align: left;

  span {
    margin-right: 1rem;
  }

  svg {
    @include anim();

    margin-left: auto;
    display: block;
    width: 2rem;
    height: 2rem;
  }

  &:hover {
    background-color: $color-white;
  }

  .is-active & {
    svg {
      transition-delay: 0.3s;
      transform: rotate(-45deg);
    }
  }

  @include bp($mobile-breakpoint) {
    span {
      margin-right: 0;
    }
  }
}

.c-accordion__trigger--table:not(.c-accordion__trigger--secondary) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.c-accordion__trigger--secondary {
  background-color: #fff;
  margin: 0 0 0 auto;
  padding: 0;
  width: 2.25rem;
  height: 2.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid $color-steel;

  svg {
    @include anim();

    display: block;
    width: 0.875rem;
    height: 0.875rem;
    transform: rotate(-45deg);
    stroke: $color-dark-blue;
  }

  .is-active & {
    svg {
      transition-delay: 0.3s;
      transform: rotate(0);
    }
  }

  @include bp($mobile-breakpoint) {
    margin: 0 0 0 1rem;
  }
}

.c-accordion__panel {
  @include anim();

  transition-timing-function: $global-timing-func;
  overflow: hidden;

  .js-enabled &{
    visibility: hidden;
  }

  // Active class
  .is-active &{
    visibility: visible;
  }
}

.c-accordion__inner {
  @include anim();
  padding: 1rem 0;

  opacity: 0;

  .is-active & {
    transition-delay: 0.3s;
    opacity: 1;
  }
}

.c-accordion__inner--table {
  @include bp($mobile-breakpoint) {
    padding: 2.5rem 0;
  }
}

.c-accordion__inner--content {
  @include fp(padding-top, 20px, 42px);
  @include fp(padding-bottom, 20px, 42px);
  @include fp(padding-left, 16px, 32px);
  @include fp(padding-right, 16px, 32px);

  border: 1px solid $color-steel;
  border-radius: 0.25rem;
  margin-top: 0.5rem;
}

.c-accordion__inner-title {
  @include fp(padding-bottom, 32px, 48px);

  font: 600 normal 1.5rem $font-primary;
  text-transform: initial;
  color: $color-dark-blue;
  border-bottom: 1px solid $color-steel;
}

.c-accordion__content {
  @include fp(margin-top, 32px, 48px);

  @include bp($mobile-breakpoint) {
    display: flex;
  }
}

.c-accordion__main-content {
  @include bp($mobile-breakpoint) {
    width: percentage(7/10);
  }
}

.c-accordion__aside-content {
  @include bp($mobile-breakpoint) {
    width: percentage(2/10);
    margin-left: percentage(1/10);
  }
}
