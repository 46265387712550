/* ==========================================================================
   #LOGO
   ========================================================================== */

.c-logo {
  fill: $color-dark-blue;
  width: 100%;
  height: 100%;
}

.c-logo-wrapper {
  display: flex;

  .c-logo + .c-logo {
    margin-left: 0.5rem;
  }
}

.c-logo--square {
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font: 700 0.75rem $font-headings;
  color: $color-dark-blue;
  border: 1px solid $color-steel;

  @include bp($mobile-breakpoint) {
    width: 2.5rem;
    height: 2.5rem;
    font-size: 0.625rem;
  }

  .c-tease__market-logos & svg {
    width: 100%;
    height: 100%;
  }
}

.c-logo--square-tease {
  position: relative;
  border-width: 0.25rem;
  width: 100%;
  height: initial;

  &::before {
    content: '';
    padding-bottom: 100%;
  }

  span {
    @include fp(font-size, 16px, 32px);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

.c-logo--footer {
  @include fp(margin-right, 16px, 32px);

  width: $global-logo-size;
  height: $global-logo-height;
}
