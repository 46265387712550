/* ==========================================================================
   GRID
   ========================================================================== */

.c-grid {
  position: relative;
  padding: 5rem 2rem;
}

.c-grid--aligned {
  padding: 3.5rem 0 2.5rem;
}

.c-grid--has-bg {
  position: static;
  background-color: var(--bgColor);
}

.c-grid--downloads {
  background-color: transparent;
}

.c-grid__header {
  color: $color-dark-blue;
  color: var(--font-color);

  @include bp($mobile-breakpoint) {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
  }
}

.c-grid__hgroup {
  margin-left: auto;
  .c-grid__header--has-btn & {
    @include bp($mobile-breakpoint) {
      width: 60%;
    }
  }

  h2 {
    font-size: 3rem;
    text-align: center;
    color: var(--up-purple);
    line-height: 1;
  }
  h2 span {
    border-color: #fff;
    display: block;
  }
}

.c-grid__sub-headline {
  text-align: center;
}

.c-grid__main {
  margin-top: 2rem;

  @include bp($mobile-breakpoint) {
    display: flex;
    flex-flow: row wrap;
    margin-left: -1rem;
  }
}

.c-grid__main--social {
  position: relative;
  @include bp($mobile-breakpoint) {
    display: block;
    margin-right: 1rem;

    &::before {
      @include ps();
      @include anim($prop: opacity);

      top: 0;
      left: 1rem;
      width: 100%;
      height: 100%;
      background-color: var(--bgColor);
      z-index: 2;
      opacity: 1;
    }

    &.is-visible {
      &::before {
        opacity: 0;
        z-index: -1;
      }
    }
  }
}

.c-grid__main--dl,
.c-grid__main--related {
  margin-top: 0;
  justify-content: center;
}

.c-grid__main--related {
  @include bp($mobile-breakpoint) {
    margin-left: -3rem;
  }
}

.c-grid__main--webcast,
.c-grid__main--job {
  @include bp($mobile-breakpoint) {
    margin-left: 0;
  }
}

.c-grid__main--contact-person,
.c-grid__main--job {
  width: 100%;
}

.c-grid__headline {
  margin: 0;
  text-transform: uppercase;
  color: inherit;
}

.c-grid__btngroup {
  margin-left: auto;
  display: flex;
  align-items: flex-start;
  margin-top: 1rem;
}

.c-grid__filter {
  width: 100%;
}

.c-grid__item {
  width: 100%;

  @include bp(medium) {
    width: calc(#{percentage(1/2)} - 2rem);
    margin-left: 1rem;
  }

  @include bp(xlarge) {
    width: calc(#{percentage(1/3)} - 2rem);

    &.is-sticky {
      width: calc(#{percentage(2/3)} - 2rem);
    }

    .c-grid__main--related & {
      width: calc(#{percentage(1/3)} - 3rem);
      margin-left: 3rem;
      margin-bottom: 3rem;
    }
  }
}

.c-grid__item--forth {
  @include bp($mobile-breakpoint) {
    width: calc(#{percentage(1/4)} - 1rem);
    margin-left: 1rem;
  }
}

.c-grid__load-more-wrapper {
  @include fp(margin-top, 40px, 80px);

  display: flex;
  justify-content: center;
}

.c-grid__load-more {
  .c-grid--has-bg & {
    color: $color-dark-blue;
    background-color: $color-white;
  }
}

.c-grid__list {
  @include bp($mobile-breakpoint) {
    width: 100%;
  }
}

.c-grid__footer {
  display: flex;
  justify-content: center;
  a {
    display: block;
  }
}
