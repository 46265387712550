.c-fact--grid.c-fact--style-video {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  border-color: transparent;
  aspect-ratio: 9/10;
  @include bp-down(large) {
    aspect-ratio: unset;
  }
  video {
    border-radius: calc(0.5rem - 1px);
  }
  .video_overlay,
  .background_image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: calc(0.5rem - 1px);
  }
  .content {
    position: relative;
    width: 100%;
    @include bp-down(small) {
      font-size: 0.9375rem;
      line-height: 1.4;
    }
  }
  h3 {
    font-size: 3rem;
    line-height: 1.2;
    hyphens: auto;
    @include bp-down(xlarge) {
      font-size: 2rem;
    }
    @include bp-down(small) {
      font-size: 1.5rem;
    }
  }
  .cta {
    margin-top: 1rem;
    text-align: right;
  }
}
