/* ==========================================================================
   #HR
   ========================================================================== */

hr {
  width: 100%;
  border: none;
  margin: 0;
  border-top: 1px solid $color-steel;
  display: block;
}
