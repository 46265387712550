/* ==========================================================================
   #BODY
   ========================================================================== */

body {
  width: 100%;
  min-height: 100%;
  hyphens: auto;
  word-wrap: break-word;
  overflow-wrap: break-word;
  overflow-x: hidden;

  &.menu-open {
    overflow: hidden;
  }

  @include bp($mobile-breakpoint) {
    display: flex;
    flex-direction: column;

    &::before {
      @include ps();
      @include anim();

      position: fixed;
      transition-property: opacity, visibility;
      visibility: hidden;
      opacity: 0;
      background-color: $color-grey;
      top: 0;
      left: 0;
      width: 100%;
      height: calc(var(--nav-backdrop-height) + 5rem + 2rem + 4rem);
      z-index: 3;
    }

    &.menu-open {
      overflow: initial;

      &:hover {
        &::before {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}
