/* ==========================================================================
   TABS
   ========================================================================== */

.c-tabs {
  @include bp($mobile-breakpoint) {
    display: flex;
  }
}

.c-tabs__main,
.c-tabs__header {
  position: relative;

  @include bp($mobile-breakpoint) {
    width: 50%;
  }
}

.c-tabs__main {
  padding: 4rem 2rem 3rem;

  @include bp($mobile-breakpoint) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @include bp(xlarge) {
    width: percentage(2/3);
  }
}

.c-tabs__header {
  padding: 4.5rem 2rem 3rem;
  background-color: $color-grey;

  @include bp($mobile-breakpoint) {
    padding: 7.5rem 6rem 15rem 0;

    &::after {
      @include ps();
      top: 0;
      right: 0;
      width: 100vw;
      height: 100%;
      background-color: inherit;
      z-index: -1;
    }
  }

  @include bp(xlarge) {
    width: percentage(1/3);
  }
}





// List
.c-tabs__list {
  margin: 1.5rem 0 0;
  list-style: none;
  display: flex;
  justify-content: center;

  @include bp($mobile-breakpoint) {
    flex-direction: column;
    margin-top: 5rem;
  }
}

.c-tabs__list--horizontal {
  flex-flow: row wrap;
}

.c-tabs__list-item {
  margin-right: 1.5rem;

  &:last-child {
    margin: 0;
  }

  @include bp($mobile-breakpoint) {
    margin-right: 0;
    margin-bottom: 1.5rem;
  }
}

.c-tabs__list-item--horizontal {
  margin-left: 1rem;
}





// Trigger
.c-tabs__trigger {
  position: relative;
  border: none;
  padding: 0;
  text-decoration: none;

  &:focus {
    outline: none;
  }

  @include bp($mobile-breakpoint) {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;
  }
}

.c-tabs__trigger--archive {
  color: $color-white;
  padding: 0.5rem 1.5rem;
  font-weight: 500;

  &:hover,
  &:focus,
  &[aria-selected="true"] {
    background-color: $color-white;
    color: $color-dark-blue;
  }

  @include bp($mobile-breakpoint) {
    margin-bottom: 0;
  }
}

.c-tabs__trigger-icon {
  @include anim($dur: 0.15s);
  @include fp(width, 64px, 96px);
  @include fp(height, 64px, 96px);

  // background-color: var(--color);
  background-color: $color-white;
  color: $color-dark-blue;
  font-size: 1.5rem;
  font-weight: bold;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before,
  &::after {
    @include ps();
  }

  &::before {
    top: 3px;
    left: 3px;
    right: 3px;
    bottom: 3px;
    outline: 3px solid $color-steel;
  }

  &::after {
    @include anim();
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: -10px 10px 20px $color-steel;
    opacity: 0;
    transform: translateY(0.25rem);
  }

  svg {
    width: 100%;
    height: 100%;
  }

  .c-tabs__trigger:hover &,
  .c-tabs__trigger[aria-selected="true"] &{
    transform: translateY(-0.5rem) skew(6deg, -5deg);

    &::before {
      outline-color: $color-white;
    }

    &::after {
      opacity: 1;
      transform: translateY(0);
    }

  }
}

.c-tabs__trigger--title {
  position: relative;
  z-index: 1;
  display: block;
  padding: 0.75rem 0 0.5rem;
  font-size: 0.75rem;
  max-width: 10ch;
  text-align: left;
  hyphens: none;
  overflow-wrap: initial;

  border-bottom: 2px solid $color-steel;

  .c-tabs__trigger:hover &,
  .c-tabs__trigger[aria-selected="true"] & {
    font-weight: bold;
    color: $color-dark-blue;
    border-bottom-color: $color-dark-blue;
  }

  @include bp($mobile-breakpoint) {
    margin-left: 1.5rem;
  }
}





// Typo
.c-tabs__content {
  max-width: 32rem;
  color: $color-dark-blue;
}

.c-tabs__headline {
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.c-tabs__button {
  width: 100%;
  margin-top: 1rem;

  @include bp($mobile-breakpoint) {
    width: initial;
  }
}
