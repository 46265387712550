/* ==========================================================================
   #POST
   Includes styles for default WP images classes
   ========================================================================== */

.c-post__head {
  background-color: var(--main-color);
  display: flex;
  flex-direction: column-reverse;

  @include bp($mobile-breakpoint) {
    flex-direction: row;
  }
}

.c-post__head-info {
  padding: 2rem;

  color: $color-dark-blue;

  .has-dark-color & {
    color: $color-white;
  }
}

.c-post__date,
.c-post__meta-head {
  @include letterSpacing(150);

  text-transform: uppercase;
  display: flex;
  align-items: center;
  font-weight: 500;

  svg {
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 1rem;
  }
}

.c-post__title {
  @include fp(font-size, 20px, 40px);
  @include fp(line-height, 28px, 48px);

  margin-top: 1rem;
  text-transform: uppercase;
  word-break: break-word;
}

.c-post__head-info,
.c-post__thumbnail {
  @include bp($mobile-breakpoint) {
    width: 50%;
  }
}

.c-post__head-info {
  @include bp($mobile-breakpoint) {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
  }
}

.c-post__share {
  display: flex;
}

.c-post__share-btn {
  padding: {
    left: 1.5rem;
    right: 1.5rem;
  }

  &:hover {
    color: $color-white;
  }

  .has-dark-color & {
    background-color: $color_white;
    color: $color-dark-blue;

    &:hover {
      border-color: $color-white;
      background-color: transparent;
      color: $color-white;
    }
  }
}

.c-post__related {
  @include fp(padding-top, 64px, 104px);
  @include fp(padding-bottom, 48px, 88px);

  padding: {
    left: 2rem;
    right: 2rem;
  };
  background-color: $color-grey;

  @include bp($mobile-breakpoint) {
    padding: {
      left: 0;
      right: 0;
    };
  }
}

.c-post__related-headline {
  @include fp(font-size, 24px, 40px);
  color: $color-dark-blue;
  margin-bottom: 0;
}

.c-post__related-header {
  @include bp($mobile-breakpoint) {
    display: flex;
    align-items: center;
  }
}

.c-post__related-header-btn {
  margin-left: auto;
}

.c-post__related-posts {
  @include fp(padding-top, 24px, 48px);

  @include bp($mobile-breakpoint) {
    display: flex;
    margin-left: -1rem;
  }
}

.alignnone {
  margin: 1em 1em 1em 0;
}

.aligncenter {
  margin: 1em auto;
}

.alignright {
  float: right;
  margin: 1em 0 1em 1em;
}

.alignleft {
  float: left;
  margin: 1em 1em 1em 0;
}

.wp-caption {
  text-align: center;
}

.wp-caption-text {
  font-style: italic;
  margin-top: 0.5em;
}
