/* ==========================================================================
   LIGHTBOX
   ========================================================================== */

.c-lightbox {
  @include anim();

  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--app-height);
  visibility: hidden;
  opacity: 0;
  background-color: rgba($color-dark-blue, 0.5);
  z-index: 201;

  &.is-active {
    visibility: visible;
    opacity: 1;
  }
}

.c-lightbox__video {
  position: relative;
  width: 100%;
  max-width: 90%;

  @include bp($mobile-breakpoint) {
    max-width: 900px;
    max-width: 70vw;
    max-height: 70vh;

    .plyr {
      max-height: inherit;
    }
  }
}

.c-lightbox__close {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 2rem;
  right: 2rem;
  width: 3rem;
  height: 3rem;
  background-color: $color-dark-blue;

  svg {
    width: 1rem;
    height: 1rem;
  }
}
