/* ==========================================================================
   PUSH
   Push flex child
   ========================================================================== */

$dirs: top bottom, right left, bottom top, left right;

@each $dirName, $dirPushfrom in $dirs {
  .u-push--#{$dirName} {
    margin-#{$dirPushfrom}: auto;
  }
}
