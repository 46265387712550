/* ==========================================================================
   MOOVE GDPR PLUGIN
   ========================================================================== */

.moove-gdpr-light-scheme {
  &#moove_gdpr_cookie_info_bar {
    body & {
      background: $color-white;
      width: 100%;
      color: $color-dark-blue;
      position: fixed;
      z-index: 100;
      max-height: none;
      bottom: 0;
      left: 0;
      border-radius: 20px;
      display: flex;
      border: 1px solid rgba($color-steel,0.3);
      box-shadow: 0 1.5rem 1.5rem $color-steel;

      padding: 2.5rem 1.5rem;

      will-change: transform;
      transition: transform 0.3s ease-in-out;

      @include bp(medium) {
        max-width: 375px;
        left: 1rem;
        bottom: 1rem;
      }
    }
  }
}

#moove_gdpr_cookie_info_bar.moove-gdpr-info-bar-hidden {
  body & {
    transform: translateY(100%);
  }
}

.moove-gdpr-info-bar-container {
  body #moove_gdpr_cookie_info_bar & {
    padding: 0;
  }
}

.moove-gdpr-info-bar-content {
  body #moove_gdpr_cookie_info_bar & {
    padding: 0 !important;
    display: flex;
    flex-direction: column;
  }
}

.moove-gdpr-cookie-notice {
  body #moove_gdpr_cookie_info_bar.moove-gdpr-light-scheme .moove-gdpr-info-bar-container .moove-gdpr-info-bar-content & {
    color: $color-dark-blue;
    font-size: 0.875rem;
    margin-bottom: 2.5rem;

    p {
      color: inherit;
      font-weight: normal;
      line-height: 22px;
    }

    h2 {
      color: $color-dark-blue;
    }

    strong {
      font-size: 1.125rem;
      margin-bottom: 0.5rem;
      display: inline-block;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }
}

.moove-gdpr-button-holder {
  body #moove_gdpr_cookie_info_bar & {
    padding: 0 !important;
    display: flex;
    width: 100%;
    flex-direction: column;
  }
}

a.mgbutton.moove-gdpr-infobar-allow-all,
button.mgbutton.moove-gdpr-infobar-allow-all {
  body #moove_gdpr_cookie_info_bar .moove-gdpr-info-bar-container .moove-gdpr-info-bar-content & {
    @extend .c-btn;
    @extend .c-btn--primary;
    @extend .c-btn--large;

    text-transform: none !important;
    font-size: 14px !important;
    font-weight: normal !important;
    letter-spacing: 0.7px !important;
    width: 100%;

    opacity: 1;
    &:not(:hover) {
      box-shadow: none !important;
    }

    &:hover {
      @extend .c-btn--primary:hover;
    }
  }
}

a.mgbutton.moove-gdpr-infobar-settings-btn,
button.mgbutton.moove-gdpr-infobar-settings-btn {
  body #moove_gdpr_cookie_info_bar:not(.gdpr-full-screen-infobar) .moove-gdpr-info-bar-container .moove-gdpr-info-bar-content & {
    @extend .c-btn;
    @extend .c-btn--transparent;
    @extend .c-btn--primary;
    @extend .c-btn--large;


    text-transform: none !important;
    font-size: 14px !important;
    font-weight: normal !important;
    letter-spacing: 0.7px !important;
    width: 100%;
    margin-top: 1rem!important;
    display: block;

    opacity: 1;
    box-shadow: none !important;
  }
}

// # Modal

.moove-gdpr-tab-main-content {
  body #moove_gdpr_cookie_modal .moove-gdpr-modal-content .moove-gdpr-tab-main & {
    font-size: 14px;
    line-height: 22px;
  }
}
