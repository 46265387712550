/* ==========================================================================
   MASONRY
   ========================================================================== */

$factSizes: full calc(100% - 2rem),
  third calc(#{percentage(1/3)} - 0.666666666rem), half calc(50% - 1rem),
  two-thirds calc(#{percentage(2/3)} - 1.25rem);

.c-masonry {
  position: relative;

  @include bp($mobile-breakpoint) {
    width: calc(100% + 1rem);
  }
}

.c-masonry__item {
  padding: 0 1rem 0 0;
  margin-bottom: 1rem;
  overflow: hidden;
  display: block;
  box-sizing: border-box;

  @include bp-down($mobile-breakpoint) {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.c-masonry__item--width-full:last-child {
  margin-bottom: 0;
}

@each $widthName, $width in $factSizes {
  @include bp($mobile-breakpoint) {
    .c-masonry__item--width-#{$widthName} {
      width: $width;
    }
  }
}
