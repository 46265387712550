/* ==========================================================================
   ALERT
   ========================================================================== */

$types: warning $color-error, info $color-success;

.c-alert {
  @include fp(padding, 16px, 32px);

  position: relative;
  z-index: 201;
  top: 0;
  color: $color-white;
  font-size: 0.875rem;
}

@each $type, $color in $types {
  .c-alert--#{$type} {
    background-color: $color;
  }
}

.c-alert__inner {
  @include bp($mobile-breakpoint) {
    display: flex;
    align-items: center;
  }
}

.c-alert__icon {
  @include fp(width, 16px, 40px);
  @include fp(height, 16px, 40px);
  @include fp(flex-basis, 16px, 40px);

  flex-shrink: 0;
  margin-right: 2rem;
  fill: currentColor;
}
