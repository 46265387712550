.c-network {
  @include fp(padding, 32px, 48px);

  .c-widget__logo-element {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    margin-bottom: 0;

    @include bp($mobile-breakpoint) {
      width: 50%;
      left: 50%;
      top: 90%;
    }

    &:before {
      opacity: 0.1;
    }
  }
}

.c-network__graph {
  @include bp($mobile-breakpoint) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.c-network__graph-section {
  @include fp(margin-bottom, 80px, 48px);

  @include bp($mobile-breakpoint) {
    max-width: 35%;
  }
}

.c-network__graph-section--first,
.c-network__graph-section--second,
.c-network__graph-section--third {
  position: relative;

  @include bp($mobile-breakpoint) {
    display: flex;
    flex-direction: column;
  }

  @include bp-down($mobile-breakpoint) {
    &:after {
      position: absolute;
      left: -0.75rem;
      top: calc(100% - 19px);
      content: '';
      display: block;

      width: 0.75rem;
      height: calc(var(--network-offset-top) + 19px);


      border-left: 1px solid var(--font-color);
      border-top: 1px solid var(--font-color);
    }

    &:before {
      position: absolute;
      left: -0.75rem;
      top: calc(100% - 19px);
      content: '';
      display: block;

      width: var(--network-offset-left-bottom);
      height: calc(var(--network-offset-top) + 19px);
      border-bottom: 1px solid var(--font-color);
    }
  }
}

.c-network__graph-section--first {
  @include bp($mobile-breakpoint) {
    transform: translateY(50%);

    &:after {
      position: absolute;
      left: calc(100% + 0.75rem);
      top: 30%;
      content: '';
      display: block;

      height: 1px;
      width: calc(var(--network-offset-desktop-top) - 1.5rem);

      border-top: 1px solid var(--font-color);
    }

    &:before {
      position: absolute;
      left: calc(100% + 0.75rem);
      top: 30%;
      content: '';
      display: block;

      width: calc(var(--network-offset-desktop-top) - 1.5rem);
      height: calc(var(--network-offset-desktop-left-bottom) - 80% - 0.75rem);

      border-right: 1px solid var(--font-color);

    }
  }
}

.c-network__graph-section--second {

  @include bp-down($mobile-breakpoint) {
    &:after {
      right: -0.75rem;
      left: auto;
      border-left: none;
      width: calc(var(--network-offset-left-top) + 0.75rem);
      border-right: 1px solid var(--font-color);

    }

    &:before {
      right: -0.75rem;
      left: auto;
    }
  }

  @include bp($mobile-breakpoint) {
    order: 10;
    margin-left: auto;
    margin-right: auto;
    margin-top: 120px;

    &:after {
      position: absolute;
      left: 50%;
      top: -50%;
      content: '';
      display: block;

      height: calc(var(--network-offset-desktop-left-bottom) - 1.5rem);
      width: calc(var(--network-offset-desktop-top) - 1rem);

      border-top: 1px solid var(--font-color);
      border-left: 1px solid var(--font-color);
    }

    &:before {
      position: absolute;
      left: 50%;
      top: calc(-50% - var(--network-offset-desktop-left-bottom) + 1.5rem);
      content: '';
      display: block;

      height: calc(var(--network-offset-desktop-left-bottom) - 1.5rem);
      width: calc(var(--network-offset-desktop-top) - 1rem);

      border-right: 1px solid var(--font-color);

    }
  }
}

.c-network__graph-section--third {

  @include bp-down($mobile-breakpoint) {
    &:after {
      right: 0.75rem;
      left: auto;
      border-left: none;
      width: calc(var(--network-offset-left-top) - 0.75rem);

      height: calc(var(--network-offset-top) - 5px);
      border-right: 1px solid var(--font-color);

    }

    &:before {
      height: calc(var(--network-offset-top) - 5px);
      width: calc(var(--network-offset-left-bottom) - 1.5rem);
      right: 0.75rem;
      left: auto;
    }
  }

  @include bp($mobile-breakpoint) {
    order: 1;
    transform: translateY(-30%);

    &:after {
      position: absolute;
      left: calc(var(--network-offset-desktop-top));
      top: calc(100% - 1.8rem);
      content: '';
      display: block;

      height: calc(var(--network-offset-desktop-left-bottom) - 1.5rem);
      width: 1px;

      border-left: 1px solid var(--font-color);
    }
  }


}

@include bp($mobile-breakpoint) {
  .c-network__graph-base {
    order: 5;
    width: 100%;
  }
}

.c-network__graph-section-label {
  @extend .c-text--label;
}

.c-network__inner {
  position: relative;
}

.c-network__intro {
  @include fp(margin-bottom, 92px, 48px);
}
