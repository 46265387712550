/* ==========================================================================
   BOX
   ========================================================================== */

.c-box {

}

.c-box__header,
.c-box__main {
  background-color: $color-grey;
  padding: {
    left: 2rem;
    right: 2rem;
  };

  @include bp($mobile-breakpoint) {
    padding: {
      left: 0;
      right: 0;
    };
    width: 100%;
    padding-left: 5.5rem;
    padding-right: 5.5rem;
  }
}

.c-box__header {
  @include fp(padding-top, 24px, 48px);
  @include fp(padding-bottom, 24px, 48px);

  border-bottom: 1px solid $color-steel;
}

.c-box__main {
  @include fp(padding-top, 32px, 64px);
}

.c-box__headline {
  @include letterSpacing(150);

  color: $color-dark-blue;
  text-align: center;
  margin: 0;
  font: 500 normal 1rem/1.5 $font-primary;
}
