/* ==========================================================================
   #INTRO
   ========================================================================== */

.c-intro {
  @include fp(padding-bottom, 48px, 64px);

  position: relative;
  min-height: calc(var(--app-height) - #{$global-logo-size});

  h1 {
    margin-bottom: 0;
  }

  @include bp($mobile-breakpoint) {
    min-height: initial;
    height: calc(var(--app-height) - #{$global-logo-size});
    max-height: 46rem;
  }
}

.c-intro--full {
  text-align: right;
  display: flex;
  flex-direction: column;

  h2 {
    @include fp(font-size, 20px, 44px);
    margin-bottom: 0;
  }
}

.c-intro--facts-text {
  padding: 6rem 2rem 4rem;

  height: initial;
  min-height: initial;
  max-height: initial;
  overflow: initial;

  @include bp($mobile-breakpoint) {
    padding: 8rem 0 4rem;
    display: flex;
    flex-flow: column nowrap;
  }
}

.c-intro__image,
.c-intro__fade {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
}

.c-intro__image {
  @include bp($mobile-breakpoint) {
    background-attachment: fixed !important;
  }
}

.c-intro--facts-parallax {
  @include bp($mobile-breakpoint) {
    max-height: initial;
    display: flex;
  }
}

.c-intro--facts-headline {
  overflow: hidden;
}

.c-intro--archive,
.c-intro--meta,
.c-intro--simple {
  @include fp(padding-top, 40px, 80px);
  @include fp(padding-bottom, 56px, 104px);

  background-color: $color-dark-blue;
  height: initial;
  min-height: initial;
  max-height: initial;
  overflow: initial;
}

.c-intro--archive,
.c-intro--simple {
  &::before {
    @include ps();
    top: 50%;
    left: 50%;
    width: 100%;
    max-width: 1300px;
    padding-bottom: 100%;
    background-image: radial-gradient($color-white, transparent 70%);
    opacity: 0.125;
    transform: translate(-50%, -50%);
  }
}

.c-intro--meta {
  @include fp(padding-top, 32px, 96px);
  @include fp(padding-bottom, 24px, 56px);

  overflow: hidden;
  &::before {
    @include ps();
    top: 50%;
    right: 25%;
    width: 100%;
    max-width: 1300px;
    padding-bottom: 100%;
    background-image: radial-gradient($color-white, transparent 70%);
    opacity: 0.125;
    transform: translate(50%, -50%);
  }
}

// Intro Header
.c-intro__header {
  position: relative;
  z-index: 1;
  text-align: center;
}

.c-intro__header--meta {
  text-align: left;
}

.c-intro__header--meta,
.c-intro__header--news {
  padding: 0 2rem;

  @include bp($mobile-breakpoint) {
    padding: 0;
  }
}

// Intro BG
.c-intro__bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -10;
}

// Intro Image
.c-intro__image {
  background: transparent 50% 50% / cover no-repeat;
}

.c-intro__image--text {
  @include bp($mobile-breakpoint) {
    $img-width: percentage(7/12);

    width: $img-width;
    background-size: $img-width auto;
    background-position: 0 $global-logo-size;
    background-attachment: fixed;
  }

  @include bp(wide) {
    $img-width: initial;

    width: 100%;
    background-size: contain;
  }

  @include bp($global-max-width) {
    background-position-x: calc((100vw - #{$global-max-width}) / 2);
  }
}

.c-intro__parallax-group {
  position: absolute;
  top: 0;
  left: 0;
  height: 60%;
  width: 100%;

  &::after {
    @include ps();
    top: 100%;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: $color-dark-blue;
    z-index: -1;
  }

  @include bp($mobile-breakpoint) {
    height: 100%;
  }
}

.c-intro__image--parallax {
  @include fp(padding-top, 360px, 600px);

  &:first-child {
    background-size: cover;
    background-position: 50% 100%;
  }

  @include bp(500px) {
    &:first-child {
      background-position-y: 0;
    }
  }

  @include bp(xlarge) {
    &:first-child {
      background-size: cover;
    }
  }

  @include bp($mobile-breakpoint) {
    &,
    &:first-child {
      background-size: cover;
    }
  }
}

// Intro Fade
.c-intro__fade {
  height: 40%;
  z-index: -1;
}

.c-intro__fade--from-left {
  height: 100%;
}

// Intro Content
.c-intro__content {
  margin-top: auto;
  position: relative;
}

.c-intro__content--full {
  @include fp(padding-left, 32px, 64px);
  @include fp(padding-right, 32px, 64px);
}

.c-intro__content--facts-text {
  @include bp($mobile-breakpoint) {
    @include fp(margin-left, 32px, 64px);
    @include fp(margin-right, 32px, 64px);
  }
}

.c-intro__content--facts-parallax {
  margin: initial;
  position: static;

  @include bp($mobile-breakpoint) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.c-intro__content--facts-headline,
.c-intro__content--facts-labeled {
  height: 100%;
  padding-top: 120px;

  @include bp($mobile-breakpoint) {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
  }
}

.c-intro__content--facts-labeled {
  height: 100%;
}

.c-intro__content-text {
  @include bp($mobile-breakpoint) {
    @include fp(font-size, 16px, 40px, 320px, 1920px);
    @include fp(padding-top, 60px, 240px, 320px, 1920px);

    padding-right: 2rem;
    width: 50%;
    color: $color-dark-blue;

    p {
      font-size: 0.75em;
      max-width: 45ch;
    }
  }
}

.c-intro__content-img {
  position: relative;
  overflow: hidden;
  width: calc(100% + 2rem);

  &::before {
    @include ps($pos: relative);

    display: block;
    width: 100%;
    padding-top: 100%;
    opacity: 1;
  }

  @include bp($mobile-breakpoint) {
    width: 50%;

    &::before {
      padding-top: calc(var(--bg-ratio) * 100%);
    }
  }
}

.c-intro__content-img-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent 0 0 / cover no-repeat;

  @include bp($mobile-breakpoint) {
    background-size: contain;
    background-position-x: 100%;
  }
}

// Intro Headline
.c-intro__headline {
  color: $color-white;

  h1 {
    @include fp(font-size, 40px, 200px);

    line-height: 1;
    text-transform: uppercase;
  }

  .c-intro__content--facts-headline &,
  .c-intro__content--facts-labeled & {
    @include fp(margin-left, 32px, 64px);
    @include fp(margin-right, 32px, 64px);

    h1 {
      @include fp(font-size, 28px, 64px);
    }

    margin: auto 0;
  }

  .c-intro__content--facts-parallax & {
    position: relative;
    top: 3rem;
    width: 100%;
    text-align: center;
  }

  .c-intro__content--facts-headline & {
    margin-bottom: 0;

    h1 {
      @include fp(font-size, 80px, 230px, 320px, 1920px);
      word-wrap: normal;
      word-break: normal;
      hyphens: none;
    }
  }

  .c-intro__content--facts-text & {
    color: var(--main-color);

    @include bp($mobile-breakpoint) {
      max-width: 480px;
    }
  }
}

.c-intro__headline--meta,
.c-intro__headline--archive {
  @include fp(font-size, 40px, 64px);

  text-transform: uppercase;
  line-height: 1.25;
}

.c-intro__facts {
  @include fp(margin-left, 32px, 64px);
  @include fp(margin-right, 32px, 64px);

  .c-intro--facts-parallax & {
    margin-top: 45%;
  }

  .c-intro--facts-labeled & {
    margin-top: 2rem;
  }

  @include bp-down($mobile-breakpoint) {
    .c-intro--facts-text & {
      margin: 0;
    }
  }

  @include bp($mobile-breakpoint) {
    .c-intro--facts-parallax & {
      margin-top: auto;
      width: 100%;
    }

    .c-intro--facts-text & {
      width: initial;
      margin-top: 4rem;
    }
  }
}

.c-intro__time {
  color: $color-white;

  + .c-intro__headline {
    margin-top: 0.5rem;
  }
}

.c-intro__deco-text-wrap,
.c-intro__deco-icon-wrap {
  display: flex;
  width: 100%;
  top: 0;
  position: absolute;
}

.c-intro__deco-icon-wrap {
  justify-content: center;

  @include bp($mobile-breakpoint) {
    top: -3rem;
  }
}

.c-intro__deco-text-wrap {
  align-items: center;
  justify-content: center;
}

.c-intro__deco-icon {
  @include fp(width, 148px, 300px);
  @include fp(height, 148px, 300px);

  margin-left: 25%;
  display: block;
  fill: $color-dark-blue;
}

.c-intro__deco-text {
  @include fp(font-size, 96px, 250px);
  @include fp(line-height, 40px, 160px);

  user-select: none;
  position: relative;
  font-weight: 700;
  z-index: 0;
  display: block;
  text-transform: uppercase;
  color: $color-dark-blue;
}

.c-intro__text {
  @include fp(margin-top, 16px, 24px);

  font-weight: 500;
  hyphens: none;
  color: $color-dark-blue;
  max-width: 45rem;
  margin: 0 auto;

  .c-intro--has-dark-bg & {
    color: $color-white;
  }
}

.c-intro--video {
  padding: 0;
  display: flex;
  flex-direction: column;
  max-height: unset;
  @include bp-down($mobile-breakpoint) {
    margin-top: 4rem;
  }
  .c-text--sticky {
    width: 100%;
  }
  .c-intro__content {
    margin-bottom: 8svh;
    transform: translate3d(0, 0, 0);
    mix-blend-mode: difference;
    @media (orientation: portrait) {
      margin-bottom: 15svh;
      z-index: 2;
    }
  }
  .c-intro__fade {
    z-index: 1;
  }
  .c-intro--video--headline {
    font-family: $font-headings;
    position: relative;
    z-index: 2;
    margin-top: auto;
    margin-bottom: 0;
    text-align: center;
    color: var(--up-purple);
    text-transform: uppercase;
    line-height: 0.71;
    font-size: 1rem;
    transform: translate3d(0, 0, 0);
    white-space: nowrap;
    opacity: 0;
    animation: fade-in 0.75s cubic-bezier(0.39, 0.575, 0.565, 1) 0.5s both;
    span > span {
      display: block;
      color: transparent;
      -webkit-text-stroke: 3px var(--up-purple);
      @include bp-down($mobile-breakpoint) {
        -webkit-text-stroke: 1px var(--up-purple);
      }
    }
  }
  @keyframes fade-in {
    0% {
      transform: translateY(100px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  img.c-intro--video-logo {
    position: absolute;
    top: 8%;
    left: 12%;
    @include bp-down(medium) {
      left: 50%;
      transform: translateX(-50%);
    }
  }
  video,
  img.c-intro--video-background-image {
    transform: translate3d(0, 0, 0);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .c-intro--video-arrow {
    position: absolute;
    z-index: 1;
    right: 0;
    top: 24%;
    width: 100%;
    mix-blend-mode: difference;
    transform: translate3d(0, 0, 0);
    @media (max-width: 767px) and (orientation: portrait) {
      width: 320%;
      top: 40%;
    }
    svg {
      width: 100%;
      fill: #8bd8d8;
    }
  }
}
