/* ==========================================================================
   PERSON
   ========================================================================== */

.c-person {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding: 0.75rem;
  color: $color-dark-blue;
}

.c-person--inline {
  padding: 0;
}

.c-person--small {
  padding: 0;
}

.c-person--story,
.c-person--statement {
  color: $color-white;
}

.c-person__logo {
  position: relative;
  width: 2.5rem;
  height: 2.5rem;

  &::before {
    @include ps($pos: relative);

    display: block;
    width: 100%;
    padding-top: 100%;
  }

  span {
    position: absolute;
    font: 700 0.75rem $font-headings;
    color: $color-dark-blue;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.c-person__data {
  margin-left: 1rem;
  display: flex;
  flex-flow: column wrap;
  line-height: 1;

  .c-nav__item--popup & {
    flex: 1 0;
  }

  .c-person--small & {
    width: calc(100% - 3.5rem - 1rem);
  }

  .is-expanded & {
    z-index: 1;
  }

  @include bp($mobile-breakpoint) {
    .c-person--inline & {
      white-space: nowrap;
      width: initial;
      display: flex;
      flex-direction: row;
    }
  }
}

.c-person__name {
  font-weight: bold;
  display: block;
  font-size: 0.875rem;
  margin-bottom: 0;
}

.c-person__meta {
  font-size: 0.75rem;

  @include bp($mobile-breakpoint) {
    .c-person--inline & {
      margin-left: 1.5rem;
    }
  }
}

.c-person__img {
  flex: 0 0 4rem;
  width: 4rem;
  height: 4rem;
  border: 1px solid $color-steel;
  border-radius: 50%;
  background: $color-white no-repeat 50% 50% / cover;

  .c-person--small & {
    flex: 0 0 3.5rem;
    width: 3.5rem;
    height: 3.5rem;
  }

  .c-person--inline & {
    flex: 0 0 3rem;
    width: 3rem;
    height: 3rem;
  }
}

.c-person__img--placeholder {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 100 100'%3E%3Cpath%0Afill='%23ccc' d='M85.45 85q.55-4.55-1.9-8.25-2.75-4-10.9-6.4-8.2-2.45-10.8-4.8-2.65-2.3-2.1-5.45 2.6-1.75 3.65-4.5 7.5 2.2 12.25-2.1-3.5-3.55-3.9-8.75-.3-8.15-1.5-14.45-1.2-6.35-4.45-10.3-3.1-3.75-8.1-4.75l-.6-.1H57q-7.1-.65-10.2 2.55-1.8-.8-3.5-1.05-.3-.05-.65-.05-3.75-.35-7.15 2-3.6 3.35-4.9 7.95-1.3 4.7-2.05 11.85L28 47.5q-.65 3.6-3.95 6.1 5.35 4 12.15 1.05 1.4 3.6 4.35 5.25.45 3.4-2.05 5.35-2.55 1.95-17 7.7-5.05 2.5-6.1 5.85-1.1 3.3-.85 6.2h70.9z'/%3E%3C/svg%3E");
  background-size: 80%;
  background-position-y: 40%;
}

.c-person__hidden-data {
  display: none;

  .is-expanded & {
    display: block;
  }
}

.c-person__contact {
  margin-left: auto;
  display: flex;
  z-index: 1;

  .is-expanded & {
    margin-top: 1.5rem;
    width: 100%;
    flex-flow:  column wrap;
  }
}

.c-person__contact-item {
  transition: none;
  background-color: $color-white;
  border: 1px solid $color-steel;
  width: 2.25rem;
  height: 2.25rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 0.825rem;
  margin-bottom: 0.5rem;

  &:hover {
    background-color: $color-dark-blue;
    fill: $color-white;
    color: $color-white;
  }

  + .c-person__contact-item {
    margin-left: 0.5rem;
  }

  .is-expanded & {
    height: 3rem;
    width: 100%;
    cursor: auto;

    + .c-person__contact-item {
      margin-left: 0;
    }
  }

  &.c-btn--grey {
    background-color: $color-grey;

    &:hover {
      background-color: $color-white;
      fill: $color-dark-blue;
    }
  }

  .c-person--inline & {
    margin-bottom: 0;
  }
}

.c-person__contact-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.25rem;
  height: 2.25rem;
  flex: 0 0 2.25rem;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 50%;

  .is-expanded & {
    margin-left: 0.375rem;
    background-color: $color-grey;
    border-color: $color-steel;

    :hover & {
      fill: $color-dark-blue;
    }
  }

  svg {
    display: block;
    width: 1rem;
    height: 1rem;
  }
}

.c-person__contact-value {
  display: none;
  margin-left: 1rem;

  .is-expanded & {
    display: block;
  }
}

.c-person__card-trigger {
  position: absolute;
  border: none;
  background: transparent;
  z-index: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  .c-person__cta & {
    @include fp(margin-left, 16px, 32px);

    position: relative;
    width: 1.5rem;
    height: 1.5rem;
  }

  .c-person--inline & {
    width: 1.25rem;
    height: 1.25rem;
  }
}

.c-person__cta {
  display: flex;
  align-items: center;
  margin-left: 4rem;

  @include bp($mobile-breakpoint) {
    margin-left: auto;
  }
}

.c-person__title--inline {
  font-weight: 700;
  font-size: 0.75rem;
}
