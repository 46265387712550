.c-widget--video-grid-v2 {
  .video-grid-content-wrapper {
    aspect-ratio: 16/9;
    width: 100%;
    position: relative;
    display: flex;
    align-items: flex-end;
    padding: 3rem 4rem;
    @include bp-down($mobile-breakpoint) {
      padding: 3rem 2.5rem;
    }
    @include bp-down(medium) {
      aspect-ratio: 3/4;
    }
  }
  .video-grid-content {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    @include bp-down($mobile-breakpoint) {
      flex-direction: column;
    }
  }

  .video_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .text {
    color: #fff;
    flex: 0 70%;
    @include bp-down($mobile-breakpoint) {
      flex: auto;
    }
    h2 {
      font-size: calc(28px + 36 * (100vw - 320px) / 1046);
      line-height: 1;
      span {
        display: block;
      }
    }
  }
  button {
    border: none;
    flex: 0 auto;
  }
}
