/* ==========================================================================
   LIST
   ========================================================================== */

.c-list--inline {
  display: flex;
  flex-flow: row wrap;
}

.c-list__item {
  display: block;
  margin-bottom: 1rem;

  .c-list--inline & {
    &:not(:last-child) {
      margin-right: 1rem;
    }
  }

  .c-list--table-like & {
    padding: 1rem 0.5rem;
    border-top: 1px solid $color-steel;

    &:last-child {
      border-bottom: 1px solid $color-steel;
    }
  }

  .c-grid--has-bg & {
    background-color: $color-white;
  }

  .c-grid__list & {
    width: 100%;
  }

  @include bp($mobile-breakpoint) {
    .c-list--table-like & {
      padding: 1rem;
    }
  }
}

.c-list--entries {
  @include fp(margin-top, 64px, 104px);
}

.c-list__item--job {
  @include fp(padding-left, 16px, 24px);
  @include fp(padding-right, 16px, 24px);

  padding: {
    top: 1rem;
    bottom: 1rem;
  }
  margin: 0;
  border-top: 1px solid $color-steel;
  color: $color-dark-blue;

  > * {
    display: block;
    margin-top: 0.5rem;

    &:first-child {
      margin-top: 0;
    }
  }

  &:last-child {
    border-bottom: 1px solid $color-steel;
  }

  svg {
    @include fp(width, 32px, 48px);
    @include fp(height, 32px, 48px);
  }

  @include bp($mobile-breakpoint) {
    display: flex;
    align-items: center;

    a {
      @include fp(margin-left, 16px, 56px);
    }

    small {
      &:first-of-type {
        margin-left: 1.5rem;
      }
    }
  }
}


[class*="c-text"] {
  ul {
    margin: 0 0 1em 0;
    list-style-type: disc;
  }

  li {
    margin-left: 1em;
    padding-left: 1em;
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid-column;
  }

}
